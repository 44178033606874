import moment from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IReservation, IWindow } from '../../../../../core/interfaces'
import { reservationService } from '../../../../../core/services'
import { DAYS, HOURS, MINUTES } from '../../../../../static/constants'
import { Button, InputText, Select } from '../../../../atoms'
import { InputDateTime } from '../../../../molecules'
import { Form } from '../../../../molecules'

declare var window: IWindow

interface IDeliveryDate {
  DD: string
  HH: string
  mm: string
}
interface IProps {
  eventFlag: boolean
  newStatus: string
  reservationId: number

  timeSpecifiedFlag?: false | boolean
  reservationPostTaxi?: null | string
  reservationPostTaxiId?: null | number
  taxiId?: null | number
  deliveryTime: number
  specifiedDeliveryTime: string
  workFlow?: null | string
  setReservation(reservaton: any): void
}

const TEMPORARY_APPROVE_FIELDS = {
  taxi_id: 'taxi_id',
}

const APPROVE_FIELDS = {
  cooking_time: 'cooking_time',
}

const ApproveModal: React.FC<IProps> = props => {
  const [newStatus, setNewStatus] = React.useState<string>(props.newStatus)
  const [taxies, setTaxies] = React.useState([])
  const [modalErrors, setModalErrors] = React.useState<{ [key: string]: null | string }>({
    reason: null,
  })
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [submittable, setSubmittable] = React.useState(false)
  const [selectedPickUpTime, setSelectedPickUpTime] = React.useState<moment.Moment | null>(
    moment(props.specifiedDeliveryTime)
  )

  const formatSelectOptions = options => {
    return options.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const getTaxies = async () => {
    const { taxiList } = await reservationService.getTaxies()
    let taxiOptions = [{ value: '', label: '-' }, ...formatSelectOptions(taxiList)]
    if (props.workFlow === 'temporary_canceled') {
      taxiOptions = taxiOptions.filter(taxi => taxi.value !== props.reservationPostTaxiId)
    }
    setTaxies(taxiOptions)
  }
  React.useEffect(() => {
    getTaxies()
  }, [])

  const handleSubmit = async (initialValues, value) => {
    if (newStatus === 'temporary_approved') {
      const params = {
        reservation_temporary_approve: value,
      }
      setIsProcessing(true)
      const { reservation, flush } = await reservationService.temporaryApproveReservation(
        props.reservationId,
        params
      )
      props.setReservation(reservation)
      setNewStatus('approved')
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    } else if (newStatus === 'approved') {
      const params = {
        reservation_approve: value,
      }
      setNewStatus('approved')
      const { reservation, flush } = await reservationService.approveReservation(
        props.reservationId,
        params
      )
      props.setReservation(reservation)
      window.globalModal.closeModal()
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
    }
  }

  const getOptionsData = name => {
    switch (name) {
      case 'cooking_time':
        return MINUTES
    }
  }
  const formatOptions = name => {
    const optionsData = getOptionsData(name)
    return optionsData.map(data => {
      return { value: data, label: `${data}` }
    })
  }

  return (
    <S.ModalWrapper>
      {props.eventFlag && (
        <Form
          fields={undefined}
          handleUpdateForm={updatedErrors => {
            setModalErrors(updatedErrors)
          }}
          handleSubmit={handleSubmit}
        >
          <S.Footer>
            {!submittable && (
              <Button
                primary={false}
                disabled={isProcessing}
                handleClick={() => setSubmittable(true)}
              >
                {I18n.t('reservation.approved')}
              </Button>
            )}
            {submittable && (
              <>
                <p>承認してよろしいですか？</p>
                <Button primary={true} disabled={isProcessing}>
                  {I18n.t('reservation.approved')}
                </Button>
              </>
            )}
          </S.Footer>
        </Form>
      )}
      {!props.eventFlag && (
        <>
          {newStatus === 'temporary_approved' && (
            <div>
              {I18n.t('reservation.select_taxi_id')} / 1次タクシー（{props.reservationPostTaxi}）
            </div>
          )}
          {newStatus === 'approved' && !props.timeSpecifiedFlag && (
            <div>{I18n.t('reservation.input_cooking_time')}</div>
          )}
          {newStatus === 'approved' && props.timeSpecifiedFlag && (
            <div>
              お客様受け取り予定日時：{' '}
              {moment(props.specifiedDeliveryTime).format('YYYY-MM-DD HH:mm')}
            </div>
          )}

          <Form
            fields={newStatus === 'temporary_approved' ? TEMPORARY_APPROVE_FIELDS : APPROVE_FIELDS}
            handleUpdateForm={updatedErrors => {
              setModalErrors(updatedErrors)
            }}
            handleSubmit={handleSubmit}
          >
            {newStatus === 'temporary_approved' && (
              <Select
                required={true}
                name="taxi_id"
                options={taxies}
                // defaultValue={props.taxiId ? props.taxiId : taxies[0].value}
              />
            )}
            {newStatus === 'approved' && !props.timeSpecifiedFlag && (
              <Select
                required={true}
                name="cooking_time"
                options={formatOptions('cooking_time')}
                defaultValue={'20'}
              />
            )}
            <S.Footer>
              {!submittable && (
                <Button
                  primary={false}
                  disabled={isProcessing}
                  handleClick={() => setSubmittable(true)}
                >
                  {newStatus === 'temporary_approved' && I18n.t('reservation.temporary_approved')}
                  {newStatus === 'approved' && I18n.t('reservation.approved')}
                </Button>
              )}
              {submittable && (
                <>
                  <p>
                    {newStatus === 'temporary_approved' && '事前承認（タクシー会社承認）'}
                    {newStatus === 'approved' && '受注完了（店舗承認）'}
                    してよろしいですか？
                  </p>
                  <Button primary={true} disabled={isProcessing}>
                    {newStatus === 'temporary_approved' && I18n.t('reservation.temporary_approved')}
                    {newStatus === 'approved' && I18n.t('reservation.approved')}
                  </Button>
                </>
              )}
            </S.Footer>
          </Form>
        </>
      )}
    </S.ModalWrapper>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.ModalWrapper = styled.div``

S.Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`
export default ApproveModal
