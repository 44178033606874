import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button, InputText, Select } from '../../../atoms'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

declare var window: IWindow

interface IProps {
  bank_accounts: {
    bankaccounts: IJsonResponse
  }
  search_params?: any
}

const options = { scope: 'activerecord.attributes.bank_account' }

const COLUMNS = [
  {
    name: '店舗ID',
    field: record => <span>{record.post ? record.post.id : '-'}</span>,
  },
  {
    name: '店舗名',
    field: record => <span>{record.post ? record.post.name : '削除済み店舗'}</span>,
  },
  {
    name: I18n.t('bank_code', options),
    field: 'bank_code',
  },
  {
    name: I18n.t('branch_code', options),
    field: 'branch_code',
  },
  {
    name: I18n.t('account_type', options),
    field: 'account_type_i18n',
  },
  {
    name: I18n.t('number', options),
    field: 'number',
  },
  {
    name: I18n.t('name', options),
    field: 'name',
  },
]

const FIELDS = {
  keyword: 'keyword',
}

const AdminBankAccountIndex: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.bank_accounts.bankaccounts)
  const [bank_accounts, setBankAccounts] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const response = await adminService.deleteBankAccount(id)
      const flush = response.flush
      window.flashMessages.addMessage({ text: flush.message, type: flush.type })
      window.globalModal.closeModal()

      setBankAccounts(bank_accounts.filter(bank => bank.id !== id))
    },
    [bank_accounts]
  )
  const searchParams = new URLSearchParams(window.location.search)

  return (
    <AdminLayoutIndex
      model="bank_account"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.bank_account')}</span>
          <Button small={true}>
            <a href="/admin/bank_accounts/new">{I18n.t('generic.create_new')}</a>
          </Button>
          <Button small={true}>
            <a href={`/admin/bank_accounts/download.csv?${searchParams.toString()}`}>
              CSVダウンロード
            </a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={bank_accounts}
          pagination={props.bank_accounts.bankaccounts.meta}
          handleDelete={handleDelete}
          getEditLink={id => `/admin/bank_accounts/${id}/edit`}
          formSearch={
            <>
              <InputText
                required={false}
                name="keyword"
                defaultValue={props.search_params.keyword || ''}
                label="キーワード"
                width={300}
              />
            </>
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminBankAccountIndex
