import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class InfoService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getInfoFromJson(data) {
    const info = this.serializer.parseResourceData(data, data.data)

    return { info }
  }

  public getInfosFromJson(data) {
    const infos = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { infos, pagination }
  }

  public async getInfo(id: number) {
    const { data } = await this.httpClient.get(`/api/infos/${id}`)
    console.log(data)
    const info = this.serializer.parseResourceData(data.info, data.info.data)
    return { info }
  }

  public async getInfos() {
    const { data } = await this.httpClient.get('/api/infos')
    const infos = this.serializer.parseResourceData(data.infos.infos, data.infos.infos.data)
    return { infos }
  }
}

export default InfoService
