import * as React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { IWindow } from '../../../core/interfaces'
import { IJsonResponse } from '../../../core/JsonApiSerializer'
import { adminService } from '../../../core/services'
import { COLORS, THEME_COLOR_VARIABLE_NAME } from '../../../static/constants'
import { Button } from '../../atoms'
import AdminLayoutIndex from './_layouts/'

declare var window: IWindow

interface IProps {
  identifications: {
    identifications: IJsonResponse
  }
}

const HIDE_STATUS = ['approved', 'declined']

const Index: React.FC<IProps> = props => {
  const { identifications: initialIdentifications } = adminService.getIdentificationsFromJson(
    props.identifications.identifications
  )
  const [identifications, setIdentifications] = React.useState(initialIdentifications)

  const approveIdentification = React.useCallback(
    async identification => {
      const {
        identification: approvedIdentification,
        flush,
      } = await adminService.approveIdentification(identification.id)
      setIdentifications(
        identifications.map(item =>
          item.id === approvedIdentification.id ? approvedIdentification : { ...item }
        )
      )
      if (window && window.flashMessages) {
        window.flashMessages.addMessage({ text: flush.message, type: flush.type })
      }
    },
    [identifications]
  )

  const declineIdentification = React.useCallback(
    async identification => {
      const {
        identification: declinedIdentification,
        flush,
      } = await adminService.declineIdentification(identification.id)
      setIdentifications(
        identifications.map(item =>
          item.id === declinedIdentification.id ? declinedIdentification : { ...item }
        )
      )
      if (window && window.flashMessages) {
        window.flashMessages.addMessage({ text: flush.message, type: flush.type })
      }
    },
    [identifications]
  )

  return (
    <AdminLayoutIndex
      model="identification"
      heading={I18n.t('activerecord.models.identification')}
      main={
        <IdentificationsTable>
          <thead>
            <tr>
              <th>{I18n.t('generic.user')}</th>
              <th>{I18n.t('generic.image')}</th>
              <th>{I18n.t('generic.status')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {identifications
              .filter(identification => !HIDE_STATUS.includes(identification.workflow_state))
              .map(identification => (
                <tr key={identification.id}>
                  <td>
                    <a href={`/user/${identification.user.username}`} target="_blank">
                      {identification.user.username}
                    </a>
                  </td>
                  <td>
                    {identification.identification_images &&
                      identification.identification_images.map(image => (
                        <img key={image.id} src={image.image_url} alt="" />
                      ))}
                  </td>
                  <td>{identification.workflow_state}</td>
                  <td className="_buttons">
                    <Button
                      handleClick={() => approveIdentification(identification)}
                      primary={true}
                    >
                      {I18n.t('generic.approve')}
                    </Button>
                    <Button handleClick={() => declineIdentification(identification)}>
                      {I18n.t('generic.decline')}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </IdentificationsTable>
      }
    />
  )
}

const IdentificationsTable = styled.table`
  img {
    display: block;
    max-width: 360px;
  }

  img + img {
    margin-top: 12px;
  }

  th,
  td {
    padding: 8px 12px;
    border: solid 1px ${COLORS.Border};

    > a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
      text-decoration: underline;
    }
  }

  td._buttons {
    .Button {
      display: block;
      width: 120px;
      margin: 0;
      font-size: 14px;
    }

    .Button + .Button {
      margin-top: 12px;
    }
  }
`

export default Index
