import * as React from 'react'
import ReactQuill from 'react-quill'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { adminService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import { CheckBox, InputText, Select, Spacer } from '../../../atoms'
import { DropZoneUploader } from '../../../atoms'
// import FormItem from '../../../atoms/_layouts/FormItem'
import { InputDateTime } from '../../../molecules'
import AdminLayoutNew from '../_layouts/new'
// import styled, { AnyStyledComponent } from 'styled-components'
const FIELDS = {
  title: 'title',
  content: 'content',
  open_reservation_at: 'open_reservation_at',
  close_reservation_at: 'close_reservation_at',
  opened: 'opened',
}

const title = I18n.t('admin.create_new', {
  model: I18n.t('info', { scope: 'activerecord.models' }),
})

interface IImage {
  image_url: string
  file?: any
}

const AdminInfoNew: React.FC<{}> = props => {
  const [image, setImage] = React.useState<IImage>()
  const [storeOpenedChecked, setOpenedChecked] = React.useState(false)
  const [quillContent, setQuillContent] = React.useState('')

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { createdInfo, flush } = await adminService.createInfo(values, imageToUpload.current)
    location.href = '/admin/infos'
  }, [])

  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()
      fileReader.onloadend = (event: any) => {
        setImage({
          file: addedImage,
          image_url: event.target.result,
        })
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const handleFileSelect = event => {
    const newImage = event.target.files[0]
    const fileReader = new FileReader()
    fileReader.onloadend = (eventRoader: any) => {
      setImage({
        file: newImage,
        image_url: eventRoader.target.result,
      })
    }
    fileReader.readAsDataURL(newImage)
  }

  const imageToUpload = React.useRef(image)
  React.useEffect(() => {
    imageToUpload.current = image
  }, [image])

  const onChangeQuill = (html, delta, source, editor) => {
    setQuillContent(editor.getHTML())
  }

  return (
    <AdminLayoutNew
      model="info"
      title={title}
      fields={FIELDS}
      indexLink="/admin/infos"
      formItems={
        <>
          <InputText required={true} name="title" defaultValue="" label={I18n.t('generic.title')} />
          <Spacer />
          <S.FormItem>
            <S.ImageWrapper>
              <S.FormItemTitle>{I18n.t('generic.catch_image')}</S.FormItemTitle>
              {image && (
                <S.PreviewImage>
                  {image.image_url === '/images/no-avatar.svg' ? (
                    <p>画像がありません</p>
                  ) : (
                    <S.ItemImage>
                      <img src={image.image_url} />
                    </S.ItemImage>
                  )}
                  <S.ImageButtons>
                    <S.FileSelectWrapper>
                      <label htmlFor="fileSelectUploader">
                        {I18n.t('generic.change')}
                        <input id="fileSelectUploader" type="file" onChange={handleFileSelect} />
                      </label>
                    </S.FileSelectWrapper>
                  </S.ImageButtons>
                </S.PreviewImage>
              )}
              <S.DropZoneWrapper hasImage={image ? true : false}>
                <DropZoneUploader onDrop={onDropHandler} />
              </S.DropZoneWrapper>
            </S.ImageWrapper>
          </S.FormItem>
          <Spacer />
          <S.FormItem>
            <S.FormItemTitle>お知らせ本文</S.FormItemTitle>
            <Spacer />
            <input type="hidden" value={quillContent} name="content" />
            <ReactQuill theme="snow" value={quillContent} onChange={onChangeQuill} />
          </S.FormItem>
          {/* <Spacer />
          <InputDateTime
            required={false}
            name="open_reservation_at"
            defaultValue=""
            label={I18n.t('generic.open_reservation_at')}
          />
          <Spacer />
          <InputDateTime
            required={false}
            name="close_reservation_at"
            defaultValue=""
            label={I18n.t('generic.close_reservation_at')}
          /> */}
          {/* <Spacer />
          <CheckBox
            name="opened"
            defaultChecked={false}
            onChangeHandler={e => setOpenedChecked(e.target.checked)}
            label={I18n.t('generic.opened')}
          /> */}
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  display: block;
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`
S.Footer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
`
S.DropZoneWrapper = styled.div<{ hasImage: boolean }>`
  display: block;
  width: 100%;
  flex: 1;
  ${({ hasImage }) =>
    hasImage &&
    `
    display: none;
  `}
`
S.ButtonLeft = styled.div`
  margin-right: 20px;
`

S.ButtonSave = styled.div`
  button {
    width: 100%;
  }
`

S.ImageWrapper = styled.div<{ hasNoImage: boolean }>`
  display: flex;
  .DropZoneUploader {
    width: 100%;
    flex: 1;
    margin: 0;
  }

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ItemImage = styled.div`
  display: block;
  position: relative;
  width: 240px;
  height: inherit;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.PreviewImage = styled.div`
  display: flex;
  flex: 1;
`
S.ImageButtons = styled.div`
  display: flex;
  flex: 1;
  max-width: 100px;
  text-align: center;
  margin-left: 50px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 20px;
  }
`

S.FileSelectWrapper = styled.div`
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 11px;
  }
  border-radius: 4px;
  outline: none;
  background-color: var(${THEME_COLOR_VARIABLE_NAME});
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.6;
  }
  > a {
    color: #fff;
  }

  input {
    width: 0;
  }
`

export default AdminInfoNew
