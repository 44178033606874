import { Button, Select } from 'components/atoms'
import { postService } from 'core/services'
import moment, { Moment } from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import downloadCSV from 'utils/csv'
import { formatPrice } from 'utils/number'
import { formatSelectOptions } from '..'
import { BREAKPOINT_TABLET_MOBILE, COLORS } from '../../../../static/constants'
interface IProps {
  posts: any
  payments: {
    ordered_count: any
    fees_per_day: any
    sales_per_day: any
    net_sales_per_day: any
    cash_sales_per_day: any
    service_fees_per_day: any
    sales_per_specified_period: number
    ordered_count_total: any
    sales_per_total: number
    fees_per_total: number
    service_fees_per_total: number
    net_sales_per_total: number
    cash_sales_per_total: number
  }
}

const getCreatedAtQuery = (date: string) => {
  return `/host/payments/index_by_date?created_at=${date}`
}

const getFormattedMonth = (m: Moment) => {
  return m.locale('en').format('YYYY-MM')
}

const params = new URLSearchParams(location.search.substring(1))
const isShowAll = params.get('all') || false
const currentPostID = params.get('post_id') || ''
const currentMonth =
  params.get('month') ||
  moment()
    .locale('en')
    .format('YYYY-MM')

const PaymentPage: React.FC<IProps> = props => {
  const [payments, setPatments] = React.useState(props.payments)
  const initPosts = postService.getPostsFromJson(props.posts.posts)
  const [posts, setPosts] = React.useState(formatSelectOptions(initPosts.posts))
  const [postId, setPostId] = React.useState<string>(currentPostID)
  const keys = Object.keys(props.payments.sales_per_day)
  const currentMoment = moment(currentMonth)
  const isCurrentMonth = getFormattedMonth(currentMoment) === getFormattedMonth(moment())

  const doFilter = (month: string | null = null) => {
    const byMonth = month ? `?month=${month}` : '?all=true'
    const byID = postId ? `&post_id=${postId}` : ''
    location.href = byMonth + byID
  }

  const showPrevMonth = () => {
    doFilter(getFormattedMonth(currentMoment.subtract(1, 'M')))
  }
  const showCurrentMoth = () => {
    doFilter(getFormattedMonth(moment()))
  }
  const showNextMonth = () => {
    doFilter(getFormattedMonth(currentMoment.add(1, 'M')))
  }
  const showAllMonth = () => {
    doFilter(null)
  }
  const getDataArray = (dataLabel): string[] => {
    return [
      dataLabel,
      payments.ordered_count[dataLabel].toString(),
      formatPrice(payments.sales_per_day[dataLabel]),
      formatPrice(payments.net_sales_per_day[dataLabel] || 0),
      formatPrice(payments.cash_sales_per_day[dataLabel] || 0),
      formatPrice(payments.fees_per_day[dataLabel]),
      formatPrice(payments.service_fees_per_day[dataLabel]),
    ]
  }
  const getBaseArray = (dataLabels): string[][] => {
    return [
      [
        '日付',
        '注文数',
        '売上合計',
        '売上(WEB決済)',
        '売上(店舗支払い)',
        '手数料',
        '店舗決済負担額',
      ],
      ...dataLabels.map(dataLabel => getDataArray(dataLabel)),
    ]
  }
  const getName = () => {
    return '売上一覧' + (isShowAll ? '全期間' : currentMoment.format('YYYY/MM'))
  }

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>売上一覧: {isShowAll ? '全期間' : currentMoment.format('YYYY/MM')}</h2>
      </div>
      <div className="Wrapper_Main">
        <div className="filters">
          <h3>期間</h3>
          <div className="buttons">
            <Button handleClick={showPrevMonth}>前月</Button>
            <Button handleClick={showCurrentMoth}>今月</Button>
            <Button handleClick={showNextMonth} disabled={isCurrentMonth}>
              次月
            </Button>
            <Button handleClick={showAllMonth}>全て</Button>
          </div>
          <h3>絞り込み</h3>
          {posts.length > 0 && (
            <>
              <div className="filter">
                <Select
                  name="posts"
                  label="店舗選択"
                  options={posts}
                  defaultValue={postId}
                  onChangeHandler={e => setPostId(e.target.value)}
                />
              </div>
              <div className="buttons">
                <Button handleClick={() => doFilter()}>結果をフィルター</Button>
              </div>
            </>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>日付</th>
              <th>注文数</th>
              <th>売上合計</th>
              <th>売上(WEB決済)</th>
              <th>売上(店舗支払い)</th>
              <th>手数料</th>
              <th>店舗決済負担額</th>
            </tr>
          </thead>
          <tbody>
            {keys.map(key => (
              <tr key={key}>
                <td>
                  <a href={getCreatedAtQuery(key)}>{key}</a>
                </td>
                <td>{payments.ordered_count[key]}</td>
                <td>{formatPrice(payments.sales_per_day[key])}</td>
                <td>{formatPrice(payments.net_sales_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.cash_sales_per_day[key] || 0)}</td>
                <td>{formatPrice(payments.fees_per_day[key])}</td>
                <td>{formatPrice(payments.service_fees_per_day[key])}</td>
              </tr>
            ))}
            <tr className="total">
              <td>合計</td>
              <td>{payments.ordered_count_total}</td>
              <td>{formatPrice(payments.sales_per_total)}</td>
              <td>{formatPrice(payments.net_sales_per_total)}</td>
              <td>{formatPrice(payments.cash_sales_per_total)}</td>
              <td>{formatPrice(payments.fees_per_total)}</td>
              <td>{formatPrice(payments.service_fees_per_total)}</td>
            </tr>
          </tbody>
        </table>
        <div className="buttons">
          <Button
            small={true}
            disabled={keys.length === 0}
            handleClick={() => downloadCSV(getBaseArray(keys), getName())}
          >
            CSVで保存
          </Button>
        </div>
        <div className="note">
          <h4>振込手数料について</h4>
          売上振込時に別途振込手数料が発生致します。
          <table>
            <tbody>
              <tr>
                <td>楽天銀行以外の金融機関</td>
                <td>145円</td>
              </tr>
              <tr>
                <td>楽天銀行</td>
                <td>0円</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 12px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    border-bottom: solid 1px ${COLORS.Border};

    h3 {
      width: 100%;
    }
  }
  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    text-align: center;
    border-bottom: solid 1px ${COLORS.Border};
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
    .total{
      background-color : #FFEFD5;
    }
  }

  .note {
    table tr {
        border:1px solid #dedede;
      }
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
