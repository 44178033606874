const downloadCSV = (dataArray: string[][], name: string) => {
  const result = dataArray
    .map((row: string[]) =>
      row.map((str: string) => {
        return '"' + (str ? str.replace(/"/g, '""') : '') + '"'
      })
    )
    .map(row => row.join(','))
    .join('\n')

  const bom = '\uFEFF'
  const blob = new Blob([bom, result], { type: 'text/csv', lastModified: Date.now() })

  const anchor: any = document.createElement('a')

  if ((globalThis as any).URL && anchor.download !== undefined) {
    anchor.download = `${name}.csv`
    anchor.href = (globalThis as any).URL.createObjectURL(blob)
    document.body.appendChild(anchor)
    anchor.click()
    anchor.parentNode.removeChild(anchor)
  } else {
    window.location.href = 'data:attachment/csv;charset=utf-8,' + encodeURIComponent(bom + result)
  }
}

export default downloadCSV

