import { Button, Select } from 'components/atoms'
import { utilService } from 'core/services'
import { postService } from 'core/services'
import moment from 'moment'
import * as React from 'react'
import styled from 'styled-components'
import downloadCSV from 'utils/csv'
import { formatPrice } from 'utils/number'
import { BREAKPOINT_TABLET_MOBILE, COLORS } from '../../../../static/constants'

interface IProps {
  posts?: any
  payments: {
    payments: any
  }
}

export const formatSelectOptions = options => {
  if (options.length === 0) {
    return []
  }
  return [
    { value: '', label: '全て' },
    ...options.map((option: { id: number; name: string }) => ({
      value: option.id.toString(),
      label: option.name,
    })),
  ]
}

const getFormattedDate = (created_at: string) =>
  moment(created_at, 'YYYYMMDD HHmmss').format('YYYY/MM/DD')
const params = new URLSearchParams(location.search.substring(1))
const filterDateLabel = params.get('created_at')
const currentPostID = params.get('post_id') || ''

const PaymentPage: React.FC<IProps> = props => {
  const { data: payments } = utilService.getDataFromJson(props.payments.payments)

  const initPosts = props.posts ? postService.getPostsFromJson(props.posts.posts) : { posts: [] }
  const [posts, setPosts] = React.useState(formatSelectOptions(initPosts.posts))
  const [postId, setPostId] = React.useState<string>(currentPostID)

  const doFilter = () => {
    params.set('post_id', postId || '')
    location.href = `?${params.toString()}`
  }

  const getDataArray = (payment): string[] => {
    return [
      payment.reservation.id.toString(),
      formatPrice(payment.post_transfer_amount),
      formatPrice(payment.net_sales_amount),
      formatPrice(payment.cash_sales_amount),
      formatPrice(payment.fee_by_post),
      formatPrice(payment.service_fee),
    ]
  }
  const getBaseArray = (paymentsData): string[][] => {
    return [
      ['注文ID', '売上合計', '売上(WEB決済)', '売上(店舗支払い)', '手数料', '店舗決済負担額'],
      ...paymentsData.map(payment => getDataArray(payment)),
    ]
  }
  const getName = () => {
    return '売上詳細一覧' + (filterDateLabel ? ` (${filterDateLabel})` : '')
  }

  return (
    <Wrapper>
      <div className="Wrapper_Header">
        <h2>{getName()}</h2>
        <Button>
          <a href="./">一覧へ戻る</a>
        </Button>
      </div>
      <div className="Wrapper_Main">
        <div className="filters">
          {posts.length > 0 && (
            <>
              <h3>絞り込み</h3>
              <div className="filter">
                <Select
                  name="posts"
                  label="店舗選択"
                  options={posts}
                  defaultValue={postId}
                  onChangeHandler={e => setPostId(e.target.value)}
                />
              </div>
              <div className="buttons">
                <Button handleClick={() => doFilter()}>結果をフィルター</Button>
              </div>
            </>
          )}
        </div>
        <table>
          <thead>
            <tr>
              <th>注文ID</th>
              <th>売上合計</th>
              <th>売上(WEB決済)</th>
              <th>売上(店舗支払い)</th>
              <th>手数料</th>
              <th>店舗決済負担額</th>
            </tr>
          </thead>
          <tbody>
            {payments.map(payment => (
              <tr key={payment.id}>
                <td>{payment.reservation.id}</td>
                <td>{formatPrice(payment.post_transfer_amount)}</td>
                <td>{formatPrice(payment.net_sales_amount)}</td>
                <td>{formatPrice(payment.cash_sales_amount)}</td>
                <td>{formatPrice(payment.fee_by_post)}</td>
                <td>{formatPrice(payment.service_fee)}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="buttons">
          <Button
            small={true}
            disabled={payments.length === 0}
            handleClick={() => downloadCSV(getBaseArray(payments), getName())}
          >
            CSVで保存
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 0 auto;
  padding: 0 12px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 14px;
  }
  .Wrapper_Header {
    display: flex;
    align-items: center;
    height: 48px;
    border-bottom: solid 1px ${COLORS.Border};
  }

  .Wrapper_Main {
    overflow: scroll;
  }

  .filters {
    display: flex;
    flex-wrap: wrap;
    padding: 8px 0;
    border-bottom: solid 1px ${COLORS.Border};

    h3 {
      width: 100%;
    }
  }
  .filter {
    padding: 8px 16px;
    max-width: 420px;
    display: flex;

    p {
      width: 140px;
    }
  }

  .buttons {
    padding: 8px;
    display: flex;
  }

  table {
    text-align: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 12px;
    }

    tr {
      th,
      td {
        padding: 4px 8px;
      }
    }
  }
`

export default PaymentPage
