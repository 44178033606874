import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { infoService } from '../../../core/services'
import { BREAKPOINT_TABLET_SMALL } from '../../../static/constants'

interface IContents {
  createdAt: string
  id: string
  info_image: {
    image_url: string
  }
  publishedAt: string
  title: string
  HTML_page_code: string
  updatedAt: string
  viewDate: string
  visible: boolean
  content: string
}

interface IProps {
  info_post: any
}

const TopNews: React.FC<IProps> = props => {
  const [info, setInfo] = React.useState<IContents>(Object)
  React.useEffect(() => {
    infoService
      .getInfo(props.info_post.id)
      .then(data => setInfo(data?.info ?? null))
      .catch(console.error)
  }, [])

  return (
    <S.TopNews>
      <S.Contents>
        {info ? (
          <S.Content>
            <S.ContentMain>
              <img src={info.info_image?.image_url} alt="" />
              <h2>{info.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: info.content }} />
            </S.ContentMain>
          </S.Content>
        ) : (
          <S.NoContents>お知らせはありません</S.NoContents>
        )}
      </S.Contents>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -20px;
`

S.Content = styled.div`
  display: flex;
  width: 50%;
  padding: 10px 20px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  width: 100%;
  word-break: break-all;

  h2 {
    font-size: 18px;
  }

  p {
    margin-top: 10px;
    font-size: 16px;
  }

  > img {
    width: 100%;
  }
`

export default TopNews
