import ClassNames from 'classnames'
import { isEqual, once } from 'lodash'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import * as interfaces from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { getMergedFilterParams, getMergedFilterQueryString } from '../../../../core/queryString'
import { postService } from '../../../../core/services'
import {
  BREAKPOINT_DESKTOP,
  BREAKPOINT_TABLET_MOBILE,
  COLORS,
  HEADER_HEIGHT,
  HEADER_HEIGHT_MOBILE,
  PAYMENT_REQUIRED,
} from '../../../../static/constants'
import injectGoogleMaps from '../../../../utils/injectGoogleMaps'
import { CustomMarker, DateRangeFilter, PriceFilter, Toggle, TypeFilter } from '../../../atoms'
import AreaSelect from '../../../organisms/AreaSelect'
import Search from '../../../organisms/Header/Search'
import PostList from '../../../organisms/PostList'
declare var window: interfaces.IWindow
interface IProps {
  isSignedIn: boolean
  favorite: boolean
  posts: any
  meta: interfaces.IPager
  tags: IJsonResponse
  categories: IJsonResponse
}

const MAP_PADDING = 32
const MAP_DEFAULT_ZOOM = 11
const urlParams = new URLSearchParams(window.location.search)
const FILTER_FIELDS = [
  'area',
  'start_date',
  'end_date',
  'min_price',
  'max_price',
  'category_ids',
  'tag_ids',
]

const mapOptions: any = {
  zoom: MAP_DEFAULT_ZOOM,
  mapTypeId: 'roadmap',
  mapTypeControl: false,
  streetViewControl: false,
  fullscreenControl: false,
  scrollwheel: false,
  clickableIcons: false,
}

function getMapBounds(googleMap) {
  const { north, south, east, west } = googleMap.getBounds().toJSON()
  const bounds = {
    northEast: { lat: north, lng: east },
    southWest: { lat: south, lng: west },
  }
  return bounds
}

const useMap = ({ favorite, posts, resetPosts, updatePostsByMapBounds }) => {
  const [map, setMap] = React.useState(null)
  React.useEffect(() => {
    if (google) {
      initializeMap()
    }
  }, [google])

  const initializeMap = () => {
    const areaParam = urlParams.get('area')
    const latParam = urlParams.get('lat')
    const lngParam = urlParams.get('lng')

    if (latParam && lngParam) {
      mapOptions.center = {
        lat: parseFloat(latParam),
        lng: parseFloat(lngParam),
      }
    }

    const initializedMap = new google.maps.Map(document.getElementById('map'), mapOptions)

    if (latParam && lngParam) {
      // Mapの初期化後, クエリパラメータで取得したlat, lngのboundsの範囲にあるpostsを返却する
      initializedMap.addListener(
        'bounds_changed',
        once(() => updatePostsByMapBounds(initializedMap))
      )
    } else if (areaParam !== null) {
      const geocoder = new google.maps.Geocoder()

      geocoder.geocode({ address: areaParam }, (results, status) => {
        if (status === google.maps.GeocoderStatus.OK) {
          const location = results[0].geometry.location
          initializedMap.panTo({ lat: location.lat(), lng: location.lng() })
          updatePostsByMapBounds(initializedMap)
        } else if (status === google.maps.GeocoderStatus.ZERO_RESULTS) {
          resetPosts()
        }
      })
    } else {
      if (posts.length === 0) {
        // 東京駅
        initializedMap.panTo({ lat: 35.68155, lng: 139.767178 })
      } else {
        // 取得したPostsが収まる範囲にfitBounds, お気に入り、キーワード検索で使用
        const latitudes = posts.map(post => post.address.latitude1)
        const longitudes = posts.map(post => post.address.longitude1)
        const minPosition = [Math.min.apply(null, latitudes), Math.min.apply(null, longitudes)]
        const maxPosition = [Math.max.apply(null, latitudes), Math.max.apply(null, longitudes)]

        if (posts.length === 1) {
          initializedMap.panTo({ lat: minPosition[0], lng: minPosition[1] })
        } else {
          const latLngBounds = new google.maps.LatLngBounds(
            new google.maps.LatLng(minPosition[0], minPosition[1]),
            new google.maps.LatLng(maxPosition[0], maxPosition[1])
          )
          initializedMap.fitBounds(latLngBounds, MAP_PADDING)
        }
      }
    }

    if (!favorite) {
      initializedMap.addListener('dragend', () => updatePostsByMapBounds(initializedMap))
      initializedMap.addListener('zoom_changed', () => updatePostsByMapBounds(initializedMap))
    }

    setMap(initializedMap)
  }
  return { map }
}

const useMarkers = ({ map, posts, toggleLike }) => {
  const [markers, setMarkers] = React.useState([])

  const getMarkerPositions = updatedPosts => {
    const markerPositions = []

    for (const post of updatedPosts) {
      const latLng = new google.maps.LatLng(post.address.latitude1, post.address.longitude1)
      markerPositions.push(latLng)
    }

    return markerPositions
  }

  const setActiveMarker = (activeMarker, currentMarkers) => {
    if (activeMarker === -1) {
      // マップをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.div) {
          marker.closeDetail()
        }
      })
    } else {
      // マーカーをクリック時に既に開いているマーカー詳細を閉じる
      currentMarkers.forEach(marker => {
        if (marker.getMarkerId() !== activeMarker) {
          marker.closeDetail()
        }
      })
    }
  }

  const setCustomMarkers = React.useCallback(
    (googleMap, updatedPosts) => {
      if (!googleMap) {
        return
      }

      // Update like
      markers.forEach(marker => {
        const post = updatedPosts.find(item => marker.args?.post?.id === item.id)
        if (post && post.user_liked !== marker.liked) {
          marker.liked = post.user_liked
        }
      })

      if (
        isEqual(
          markers.map(marker => marker.markerId),
          updatedPosts.map(post => post.id)
        )
      ) {
        return
      }

      // Remove markers
      markers.forEach(marker => marker.setMap(null))

      const markerPositions = getMarkerPositions(updatedPosts)
      const updatedMarkers = updatedPosts.map((post, index) =>
        CustomMarker(google.maps)(markerPositions[index], googleMap, {
          post,
          toggleLike,
          setActiveMarker: markerId => setActiveMarker(markerId, updatedMarkers),
        })
      )
      setMarkers(updatedMarkers)

      googleMap.addListener('click', () => {
        updatedMarkers.forEach(marker => {
          if (marker.div) {
            marker.closeDetail()
          }
        })
      })

      return updatedMarkers
    },
    [map, posts]
  )

  const handleOnMouseEnter = React.useCallback(
    markerId => {
      markers.forEach(marker => {
        if (markerId === marker.markerId) {
          marker.handleOnMouseEnter()
        }
      })
    },
    [markers]
  )

  const handleOnMouseLeave = () => {
    markers.forEach(marker => marker.handleOnMouseLeave())
  }

  React.useEffect(() => {
    setCustomMarkers(map, posts)
  }, [map, posts])

  return { markers, handleOnMouseEnter, handleOnMouseLeave }
}

const searchPost = async baseParams => {
  const keywords = urlParams.get('keywords')
  const params = { ...baseParams }

  if (keywords) {
    params.keywords = keywords
  }

  return postService.search(params)
}

const usePosts = ({ favorite, initialPosts = [], initialPagination = null }) => {
  const keyword = urlParams.get('keywords')
  const categoryIds = urlParams.get('category_ids[]')
  const [loading, setLoading] = React.useState(favorite || keyword || categoryIds ? false : true)
  const [posts, setPosts] = React.useState(initialPosts)
  const [pagination, setPagination] = React.useState(initialPagination)
  // Map非表示に、表示されてたときのboundsを保持しておく
  const [currentMapBounds, setCurrentMapBounds] = React.useState(null)
  const [showMap, setShowMap] = React.useState(true)

  const resetPosts = React.useCallback(() => {
    setPosts([])
    setPagination(null)
    setLoading(false)
  }, [])

  const updatePostsByMapBounds = React.useCallback(async googleMap => {
    if (!googleMap.getBounds()) {
      return
    }
    setLoading(true)
    const filterParams = getMergedFilterParams(FILTER_FIELDS)
    const bounds = getMapBounds(googleMap)
    const params = { bounds, ...filterParams }
    const result = await searchPost(params)

    setPosts(result.posts)
    setPagination(result.pagination)
    setLoading(false)
  }, [])

  const { map } = useMap({
    favorite,
    posts,
    resetPosts,
    updatePostsByMapBounds,
  })

  const toggleShowMap = React.useCallback(
    (show: boolean) => {
      if (!show && map) {
        const bounds = getMapBounds(map)
        setCurrentMapBounds(bounds)
      } else {
        setCurrentMapBounds(null)
      }
      setShowMap(show)
    },
    [map]
  )

  const getPostsByPage = React.useCallback(
    async page => {
      setLoading(true)
      const bounds = currentMapBounds ? currentMapBounds : getMapBounds(map)
      const result = favorite
        ? await postService.getFavorites({ page })
        : await searchPost({ bounds, page })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
    },
    [map, currentMapBounds]
  )

  const handleOnFilterSubmit = React.useCallback(
    async filterValue => {
      setLoading(true)

      if (!map) {
        return
      }

      const bounds = getMapBounds(map)
      const filterParams = getMergedFilterParams(FILTER_FIELDS, filterValue)
      const filterQueryString = getMergedFilterQueryString(FILTER_FIELDS, filterParams)
      const result = await searchPost({ bounds, ...filterParams })

      setPosts(result.posts)
      setPagination(result.pagination)
      setLoading(false)
      history.replaceState(null, null, `?${filterQueryString}`)
    },
    [map]
  )

  const toggleLike = (postId, like) => {
    const detailMarker = document.getElementById(`marker-detail-${postId}`)

    // Update like of the opened detail marker
    if (detailMarker) {
      const el = detailMarker.querySelector('.Marker_Favorite')

      if (!el) {
        return
      }

      if (like) {
        el.classList.add('liked')
      } else {
        el.classList.remove('liked')
      }
    }

    setPosts(currentPosts =>
      currentPosts.map(postItem => ({
        ...postItem,
        user_liked: postItem.id === postId ? like : postItem.user_liked,
      }))
    )
  }

  return {
    showMap,
    toggleShowMap,
    loading,
    setLoading,
    map,
    posts,
    toggleLike,
    getPostsByPage,
    pagination,
    handleOnFilterSubmit,
  }
}

export const PostSearchWithMap: React.FC<IProps> = props => {
  // show area select panels
  if (
    !urlParams.get('area') &&
    !urlParams.get('keywords') &&
    !urlParams.get('category_ids[]') &&
    !props.favorite
  ) {
    location.href = '/posts/search?area=%E6%9C%AD%E5%B9%8C'
    // return <AreaSelect />
  } else {
    const PostSearchIndexWithGoogleMap = injectGoogleMaps(PostSearchIndex)
    return <PostSearchIndexWithGoogleMap {...props} />
  }
}

const PostSearchIndex: React.FC<IProps> = props => {
  const { posts: initialPosts, pagination: initialPagination } = React.useMemo(
    () => postService.getPostsFromJson(props.posts.posts),
    []
  )
  const defaultKeyword = React.useMemo(() => urlParams.get('keywords'), [])

  const {
    showMap,
    toggleShowMap,
    loading,
    map,
    posts,
    toggleLike,
    getPostsByPage,
    pagination,
    handleOnFilterSubmit,
  } = usePosts({
    favorite: props.favorite,
    initialPosts:
      props.favorite || urlParams.get('keywords') || urlParams.get('category_ids[]')
        ? initialPosts
        : [],
    initialPagination: props.favorite ? initialPagination : null,
  })

  const { handleOnMouseEnter, handleOnMouseLeave } = useMarkers({
    toggleLike,
    posts,
    map,
  })

  return (
    <S.PostSearch className="PostSearch">
      {!props.favorite && (
        <SearchHeader
          handleOnFilterSubmit={handleOnFilterSubmit}
          categories={props.categories}
          tags={props.tags}
          showMap={showMap}
          setShowMap={toggleShowMap}
        />
      )}
      <PostList
        loading={loading}
        title={count =>
          props.favorite
            ? I18n.t('post.result_favorite', { count })
            : I18n.t('post.result_search', { count })
        }
        handleOnMouseEnter={handleOnMouseEnter}
        handleOnMouseLeave={handleOnMouseLeave}
        getPostsByPage={getPostsByPage}
        isSignedIn={props.isSignedIn}
        favorite={props.favorite}
        posts={posts}
        toggleLike={toggleLike}
        meta={pagination}
        showMap={showMap}
        keyword={defaultKeyword}
        hasMap={true}
      />
    </S.PostSearch>
  )
}

interface ISearchHeaderProps {
  categories: IJsonResponse
  tags: IJsonResponse
  showMap: boolean
  handleOnFilterSubmit(params: any): void
  setShowMap(showMap: boolean): void
}

const SearchHeader: React.FC<ISearchHeaderProps> = props => {
  const [isFilterPanelOpen, setIsFilterPanelOpen] = React.useState(false)
  const { data: categories } = postService.getDataFromJson(props.categories)
  const { data: tags } = postService.getDataFromJson(props.tags)

  return (
    <S.SearchHeader className={ClassNames('SearchHeader', { isFilterPanelOpen })}>
      <Search />
      {/* <S.FilterList>
        <DateRangeFilter
          name={I18n.t('date')}
          field="date"
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          handleOnSubmit={props.handleOnFilterSubmit}
        />
        {PAYMENT_REQUIRED && (
          <PriceFilter
            name={I18n.t('generic.price')}
            field="price"
            setIsFilterPanelOpen={setIsFilterPanelOpen}
            handleOnSubmit={props.handleOnFilterSubmit}
          />
        )}
        <TypeFilter
          name={I18n.t('generic.category')}
          field="category_ids"
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          handleOnSubmit={props.handleOnFilterSubmit}
          types={categories}
        />
        <TypeFilter
          name={I18n.t('generic.tag')}
          field="tag_ids"
          setIsFilterPanelOpen={setIsFilterPanelOpen}
          handleOnSubmit={props.handleOnFilterSubmit}
          types={tags}
        />
      </S.FilterList> */}
      <S.SearchHeader_MapToggle>
        <span>地図を表示</span>
        <Toggle
          name="toggle_map"
          checked={props.showMap}
          onChangeHandler={() => {
            props.setShowMap(!props.showMap)
          }}
        />
      </S.SearchHeader_MapToggle>
    </S.SearchHeader>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.SearchHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 56px;
  padding: 10px 24px;
  border-bottom: solid 1px ${COLORS.Border};
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    overflow-x: auto;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    ::-webkit-scrollbar {
      display: none;
    }
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    height: auto;
    padding: 6px 12px;
  }

  &.isFilterPanelOpen {
    overflow: visible;
  }
`
S.SearchHeader_MapToggle = styled.div`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  @media (max-width: ${BREAKPOINT_DESKTOP}px) {
    display: none;
  }

  > span {
    margin-right: 12px;
    font-size: 14px;
  }
`

S.FilterList = styled.div`
  /* display: flex; */
  margin: 0 -6px;
  white-space: nowrap;

  > div {
    display: inline-block;
  }
`

S.PostSearch = styled.div`
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  left: 0;
  right: 0;
  bottom: 0;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    top: ${HEADER_HEIGHT_MOBILE}px;
  }
  .Wrapper_NoResult {
    font-weight: bold;
  }

  .PostList {
    @media (max-width: ${BREAKPOINT_DESKTOP}px) {
      flex: 1;
    }
  }

  .PostList_Main {
    width: 768px;
    @media (max-width: ${BREAKPOINT_DESKTOP}px) {
      width: auto;
    }
  }
`

export default PostSearchWithMap
