import axios from 'axios'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { IPost } from '../../core/interfaces'
import { ICategory } from '../../core/interfaces'
import { postService } from '../../core/services'
import { BREAKPOINT_TABLET_LARGE, BREAKPOINT_TABLET_MOBILE, COLORS } from '../../static/constants'
import { getOS } from '../../utils/getOS'
import SliderMenu from '../molecules/SliderMenu'

const TopCategoryPosts: React.FC<{}> = props => {
  const [categoriesposts, setCategoriesPosts] = React.useState({})
  const [categories, setCategories] = React.useState<ICategory[]>(null)

  const getData = async () => {
    const {
      all_cat_posts: categoriesPosts,
      categories: allCategories,
    } = await postService.getCategoriesPosts()
    setCategoriesPosts(categoriesPosts)
    setCategories(allCategories)
  }

  React.useEffect(() => {
    getData()
  }, [])

  const isPublished = (state: 'published' | 'closed' | 'suspended' | 'draft') => {
    return state === 'published'
  }
  const TIME_FORMAT = new RegExp('^([01]?[0-9]|2[0-4]):([0-5][0-9])$')
  const checkTimeValue = (start, end) => {
    return start && end && TIME_FORMAT.test(start) && TIME_FORMAT.test(end)
  }

  function onClickLink(e, category, post) {
    e.preventDefault()
    const os = getOS()
    const aTag = document.createElement('a')
    aTag.target = '_blank'
    if (os === 'android' || os === 'iOS') {
      aTag.href =
        'https://todoxi.page.link/?link=https://v2.todoxi.jp&apn=todoxi.order&ibi=todoxi.order&isi=1636838824&efr=1'
    } else {
      aTag.href = `/app_download`
    }
    aTag.click()
  }

  return (
    <S.CategoryPosts>
      {categories?.length > 0 &&
        categories.map(
          category =>
            Object.keys(categoriesposts[category.id]).length > 0 && (
              <SliderMenu title={category.name} key={category.id} link={category.id}>
                {Object.keys(categoriesposts[category.id]).map(post => (
                  <S.CategoryItemWrapper
                    key={categoriesposts[category.id][post].id}
                    className={!isPublished(categoriesposts[category.id][post].aasm_state)}
                  >
                    <S.Item
                      href="#"
                      onClick={e => onClickLink(e, category, post)}
                      title={categoriesposts[category.id][post].name}
                    >
                      <S.ItemContainer>
                        {categoriesposts[category.id][post].is_open ? (
                          !isPublished(categoriesposts[category.id][post].aasm_state) ? (
                            <S.CloseImage>
                              {categoriesposts[category.id][post].post_image &&
                              categoriesposts[category.id][post].post_image.image_url ? (
                                <img
                                  src={categoriesposts[category.id][post].post_image.image_url}
                                  alt=""
                                />
                              ) : (
                                <S.NoImage>No Image</S.NoImage>
                              )}
                              <S.CloseMsg>受付停止中</S.CloseMsg>
                            </S.CloseImage>
                          ) : (
                            <S.Image>
                              {categoriesposts[category.id][post].post_image &&
                              categoriesposts[category.id][post].post_image.image_url ? (
                                <img
                                  src={categoriesposts[category.id][post].post_image.image_url}
                                  alt=""
                                />
                              ) : (
                                <S.NoImage>No Image</S.NoImage>
                              )}
                            </S.Image>
                          )
                        ) : (
                          <S.CloseImage>
                            {categoriesposts[category.id][post].post_image &&
                            categoriesposts[category.id][post].post_image.image_url ? (
                              <img
                                src={categoriesposts[category.id][post].post_image.image_url}
                                alt=""
                              />
                            ) : (
                              <S.NoImage>No Image</S.NoImage>
                            )}
                            <S.CloseMsg>受付時間外</S.CloseMsg>
                          </S.CloseImage>
                        )}
                        <S.Info>
                          <S.Name>{categoriesposts[category.id][post].name}</S.Name>
                          {isPublished(categoriesposts[category.id][post].aasm_state) && (
                            <span className="hours">
                              <S.Times>
                                {checkTimeValue(
                                  categoriesposts[category.id][post].start_time1,
                                  categoriesposts[category.id][post].end_time1
                                ) && (
                                  <S.OpenTime>
                                    <i className="material-icons">store_mall_directory</i>
                                    {categoriesposts[category.id][post].start_time1} -{' '}
                                    {categoriesposts[category.id][post].end_time1}
                                  </S.OpenTime>
                                )}
                                {checkTimeValue(
                                  categoriesposts[category.id][post].start_time2,
                                  categoriesposts[category.id][post].end_time2
                                ) && (
                                  <S.OpenTime>
                                    <i className="material-icons">navigate_next</i>
                                    {categoriesposts[category.id][post].start_time2} -{' '}
                                    {categoriesposts[category.id][post].end_time2}
                                  </S.OpenTime>
                                )}
                                {checkTimeValue(
                                  categoriesposts[category.id][post].start_time3,
                                  categoriesposts[category.id][post].end_time3
                                ) && (
                                  <S.OpenTime>
                                    <i className="material-icons">navigate_next</i>
                                    {categoriesposts[category.id][post].start_time3} -{' '}
                                    {categoriesposts[category.id][post].end_time3}
                                  </S.OpenTime>
                                )}
                              </S.Times>
                            </span>
                          )}
                        </S.Info>
                      </S.ItemContainer>
                    </S.Item>
                  </S.CategoryItemWrapper>
                ))}
              </SliderMenu>
            )
        )}
    </S.CategoryPosts>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.CategoryPosts = styled.div`
  width: 100%;
  max-width: 1360px;
  overflow: hidden;
  margin: auto;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
`

S.CategoryItemWrapper = styled.div`
  position: relative;
  height: 240px;
  max-width: 320px;

  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    max-width: 160px;
    height: 180px;
  }

  :hover {
    opacity: 0.8;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }
`
S.Item = styled.a`
  color: ${COLORS.Text};
  overflow: hidden;

  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin-top: 16px;
  }
`
S.ItemContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 4px;
  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding: 0;
  }
`
S.NoImage = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e4e4e4;
`

S.Name = styled.p`
  white-space: normal;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  font-size: 16px;
  font-weight: normal;
  margin: 8px 0;
  white-space: pre-wrap;

  @media screen and (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 12px;
    margin-top: 0;
  }
`
S.State = styled.p`
  font-size: 12px;
`
S.Image = styled.div`  
  height: 138px
  overflow: hidden;
  z-index: -1;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`
S.CloseImage = styled.div`  
  height: 138px
  overflow: hidden;
  z-index: -1;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  ::after {
    content: '';
    background-color: rgba(0,0,0,.5);
    position: absolute;
    left: 1px;
    right: 1px;
    top: 0px;
    bottom: 0;
    height: 138px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
  
`
S.CloseMsg = styled.p`
  margin: 0;
  padding: 0.2em;
  font-size: 2rem;
  color: #fff;
  font-weight: bold;
  position: relative;
  z-index: 1;
  margin-top: -70px;
  text-align: center;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    font-size: 1rem;
    padding: 1em;
  }
`

S.Info = styled.div`
  width: 100%;
  min-height: 72px;
  line-height: 1em;
  background-color: #fff;
  padding: 4px 4px 4px 10px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.15);
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    padding-left: 16px;
    flex: 1;
  }
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    padding-left: 4px;
    min-height: 64px;
  }
  .hours {
    font-size: 0.8rem;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      font-size: 0.6rem;
    }
  }
`
S.Tags = styled.div`
  /* display: flex; */
  overflow: hidden;
  text-overflow: ellipsis;
`

S.Tag = styled.span`
  margin-top: 8px;
  color: #7c7c7c;
  font-size: 12px;
`
S.Times = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    display: inline-flex;
    align-items: center;
    @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
      margin: 8px 0;
    }
  }
`
S.OpenTime = styled.div`
  flex-shrink: 0;
  margin-right: 16px;
  @media (max-width: ${BREAKPOINT_TABLET_MOBILE}px) {
    margin: 0;
  }
  i {
    font-size: 17px;
    color: #fb585c;
  }
`

export default TopCategoryPosts
