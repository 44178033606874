import moment, { Moment } from 'moment'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IRole, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button, InputText, Select, Spacer } from '../../../atoms'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

interface IProps {
  role?: IRole
  reports: any
  search_params: any
  filter_taxis: any
}

const COLUMNS = [
  {
    name: '月',
    field: 'month_text',
    required: false,
    readOnly: true,
  },
  {
    name: '輸送回数',
    field: 'num_of_transportation',
    required: true,
  },
  {
    name: '輸送距離（km）',
    field: 'distance_transportation',
    required: true,
  },
  {
    name: '期間運賃総額',
    field: 'estimated_taxi_fare',
    required: true,
  },
  {
    name: '輸送個数',
    field: 'transport_quality',
    required: true,
  },
  {
    name: '輸送重量（t)',
    field: 'weight_transportation',
    required: true,
  },
]

const FIELDS = {
  year: 'year',
  taxi: 'taxi',
}

const yearOptions = () => {
  const years = []
  for (
    let i = 2015;
    i <=
    Number(
      moment()
        .locale('en')
        .format('YYYY')
    );
    i++
  ) {
    years.push({ label: i, value: i })
  }
  return years
}

const AdminReportIndex: React.FC<IProps> = props => {
  const formatSelectOptions = selectOptions => {
    return selectOptions.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const taxiList = formatSelectOptions(props.filter_taxis)
  const [taxies, setTaxies] = React.useState(taxiList)
  const searchParams = new URLSearchParams(window.location.search)
  const isTaxi = props.role?.id === 4

  return (
    <AdminLayoutIndex
      role={props.role}
      model="report"
      heading={
        <S.Heading>
          <span>実績報告書</span>
          <Button small={true}>
            <a href={`/admin/reports/download.csv?${searchParams.toString()}`}>CSVダウンロード</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTable
          editable={true}
          columns={COLUMNS}
          records={props.reports}
          formSearch={
            <>
              <Spacer />
              <Select
                required={false}
                name="year"
                label="年"
                options={yearOptions()}
                defaultValue={
                  props.search_params.year ||
                  moment()
                    .locale('en')
                    .format('YYYY')
                }
                width={300}
              />
              <Spacer />
              {!isTaxi && (
                <Select
                  required={false}
                  name="taxi"
                  label="受注タクシー会社"
                  options={taxies}
                  defaultValue={props.search_params.taxi}
                  width={300}
                />
              )}
            </>
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminReportIndex
