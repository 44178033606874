import { IPager, IWindow } from 'core/interfaces'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../core/i18n'
import { COLORS } from '../../static/constants'
import { Button } from '../atoms'
import Pagination from '../atoms/Pagination'

declare var window: IWindow

interface IColumn {
  name: string
  field: string | ((record) => React.ReactElement)
  required?: boolean
  readOnly?: boolean
}

interface IProps {
  title?: string | React.ReactElement
  columns: IColumn[]
  records: any[]
  editable: boolean
  pagination?: IPager
  isFixed?: boolean
  currentUserId?: number
  handleDelete?(id: number): void
  getEditLink?(id: number): string
  renderRecordControls?(record: any): React.ReactElement
}

const EditTableInfo: React.FC<IProps> = ({
  title,
  columns,
  records,
  editable,
  pagination,
  handleDelete,
  getEditLink,
  isFixed,
  currentUserId,
  renderRecordControls,
}) => {
  const sizerRef = React.useRef(null)
  const tableRef = React.useRef(null)
  const sizerWidth = isFixed ? '100%' : '0px'
  const onChangePageHandler = React.useCallback(page => {
    location.href = `${location.pathname}?page=${page}`
  }, [])
  return (
    <S.EditTableInfo>
      {title && <S.Title>{title}</S.Title>}
      <S.Scroller>
        <S.Sizer ref={sizerRef} theme={{ width: sizerWidth }}>
          <S.Table ref={tableRef}>
            <thead>
              <tr>
                {editable && getEditLink && <th />}
                {columns.map(field => (
                  <th key={field.name}>{field.name}</th>
                ))}
                {editable && handleDelete && <th />}
              </tr>
            </thead>
            <tbody>
              {records.map(record => (
                <EditTableInfoRow
                  key={record.id}
                  editable={editable}
                  deletable={record.role === undefined ? true : record.role.name !== 'admin'}
                  columns={columns}
                  record={record}
                  handleDelete={handleDelete}
                  getEditLink={getEditLink}
                  currentUserId={currentUserId}
                  renderRecordControls={renderRecordControls}
                />
              ))}
            </tbody>
          </S.Table>
        </S.Sizer>
      </S.Scroller>
      {pagination && (
        <Pagination
          onChangePageHandler={onChangePageHandler}
          currentPage={pagination.current_page}
          prevPage={pagination.prev_page}
          nextPage={pagination.next_page}
          totalPages={pagination.total_pages}
          totalCount={pagination.total_count}
        />
      )}
    </S.EditTableInfo>
  )
}

interface ICategory {
  id: number
  name_i18n: string
  position: number
}

interface IEditTableInfoRowProps {
  editable: boolean
  deletable?: boolean
  columns: IColumn[]
  record: ICategory
  currentUserId?: number
  handleDelete?(id: number): void
  getEditLink(id: number): string
  renderRecordControls?(record: any): React.ReactElement
}

const EditTableInfoRow: React.FC<IEditTableInfoRowProps> = ({
  editable,
  deletable,
  columns,
  record,
  currentUserId,
  handleDelete,
  getEditLink,
  renderRecordControls = null,
}) => {
  const showModal = () => {
    window.globalModal.showModal({
      title: '本当に削除しますか？',
      closeText: I18n.t('generic.cancel'),
      submitText: 'お知らせを削除する',
      submitDanger: true,
      handleSubmit: () => handleDelete(record.id),
    })
  }
  return (
    <tr>
      {editable && typeof getEditLink === 'function' && (
        <td className="_buttons">
          <Button key="edit" small={true}>
            <a href={getEditLink(record.id)}>{I18n.t('generic.edit')}</a>
          </Button>
        </td>
      )}
      {columns.map(column => (
        <td key={column.name}>
          {(typeof column.field === 'string' || typeof column.field === 'number') && (
            <span>{record[column.field]}</span>
          )}
          {typeof column.field === 'function' && <div>{column.field(record)}</div>}
        </td>
      ))}
      {currentUserId !== undefined
        ? renderRecordControls &&
          record.id !== currentUserId && <td>{renderRecordControls(record)}</td>
        : renderRecordControls && <td>{renderRecordControls(record)}</td>}
    </tr>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.EditTableInfo = styled.div`
  .Pagination {
    padding-bottom: 24px;
  }
`

S.Title = styled.h1`
  font-size: 18px;
  padding: 16px 20px;

  .Button {
    margin-left: 16px;
  }
`

S.Scroller = styled.div`
  overflow: scroll;
  padding-bottom: 16px;
`

S.Sizer = styled.div`
  width: ${({ theme }) => theme.width};
`

S.Table = styled.table`
  width: auto;
  border-bottom: solid 1px ${COLORS.Border};

  th,
  td {
    max-width: 240px;
    min-width: 40px;
    padding: 4px 8px;
    vertical-align: middle;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-right: solid 1px ${COLORS.Border};

    &._buttons {
      width: 180px;
    }
  }

  tr {
    border-top: solid 1px ${COLORS.Border};
  }
`

export default EditTableInfo
