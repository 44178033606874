import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL, THEME_COLOR_VARIABLE_NAME } from '../../../../static/constants'
import STATE_CODES from '../../../../static/stateCodes'
import { Button, InputText, InputTextV2, Select, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  taxi: IJsonResponse
  address: IJsonResponse
}
interface INotificationOption {
  notify_to_email: boolean
  notify_to_fax: boolean
}
const FIELDS = {
  id: 'id',
  name: 'name',
  email: 'email',
  phone: 'phone',
  fax: 'fax',
  postal_code1: 'postal_code1',
  city1: 'city1',
  state1: 'state1',
  other1_1: 'other1_1',
  other1_2: 'other1_2',
  notification_option: 'notification_option',
  notify_url: 'notify_url',
  login_id: 'login_id',
  login_password: 'login_password',
  delivery_reception_status: 'delivery_reception_status',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('taxi', { scope: 'activerecord.models' }),
})
const keys_of_address = ['postal_code1', 'city1', 'state1', 'other1_1', 'other1_2', 'fax', 'phone']
const ignore_keys = ['notification_option']
const notificationValues = {
  fax: {
    notify_to_email: false,
    notify_to_fax: true,
  },
  email: {
    notify_to_email: true,
    notify_to_fax: false,
  },
  both: {
    notify_to_email: true,
    notify_to_fax: true,
  },
}
const getDefaultNotificationValue = ({ notify_to_email, notify_to_fax }) => {
  if (notify_to_email && notify_to_fax) {
    return 'both'
  }
  if (notify_to_email) {
    return 'email'
  }
  if (notify_to_fax) {
    return 'fax'
  }

  return ''
}
const AdminTaxiEdit: React.FC<IProps> = props => {
  const { data: taxi } = adminService.getDataFromJson(props.taxi)
  const [password, setPassword] = React.useState<string>(
    taxi.view_password ? taxi.view_password : null
  )
  const [loginId, setLoginId] = React.useState<string>(taxi.login_id ? taxi.login_id : null)

  const defaultNotificationValue = getDefaultNotificationValue(taxi)
  const initNotificationOption = notificationValues[defaultNotificationValue]
  const [selectedNotificationOption, setSelectedNotificationOption] = React.useState<
    INotificationOption
  >(initNotificationOption)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const taxiParam = { ...notificationValues[values.notification_option] }
    const addressParam = {}
    Object.keys(values).forEach(key => {
      const value = values[key]
      if (ignore_keys.indexOf(key) !== 0) {
        if (keys_of_address.indexOf(key) === -1) {
          taxiParam[key] = value
        } else {
          addressParam[key] = value
        }
      }
    })

    const { updatedTaxi, flush } = await adminService.updatetaxi({
      taxi: taxiParam,
      address: addressParam,
    })
    location.href = '/admin/taxies'
  }, [])

  const stateOptions = [
    { value: '', label: '-' },
    ...STATE_CODES.map(stateName => ({
      value: stateName,
      label: stateName,
    })),
  ]

  const notificationOptions = [
    { value: '', label: '-' },
    // { value: 'fax', label: 'FAX' },
    { value: 'email', label: 'メール' },
    // { value: 'both', label: 'FAXとメール' },
  ]

  const changeNotificationOption = event => {
    const value = notificationValues[event.target.value]
    if (value) {
      setSelectedNotificationOption(value)
    }
  }

  const generateLoginId = () => {
    let result = ''
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789'
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    result += taxi.id
    setLoginId(result)
  }

  const generatePassword = () => {
    let result = ''
    const characters = 'ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz123456789'
    for (let i = 0; i < 8; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length))
    }
    result += taxi.id
    setPassword(result)
  }

  const deliveryReceptionStatusOption = [
    { value: 'on', label: 'ON' },
    { value: 'off_hour', label: '1時間OFF' },
    { value: 'off_day', label: '1日OFF' },
  ]

  return (
    <AdminLayoutEdit
      model="taxi"
      title={title}
      fields={FIELDS}
      indexLink="/admin/taxies"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={taxi.id} label="ID" />
          <Spacer />
          <S.FormItem>
            <InputTextV2 readonly={true} name="login_id" value={loginId} label="アプリログインID" />
            {!taxi.login_id && (
              <S.Buttons>
                <Button
                  primary={false}
                  backgroundColor="#a7813f"
                  textColor="#fff"
                  handleClick={() => {
                    generateLoginId()
                  }}
                >
                  ログインID生成
                </Button>
              </S.Buttons>
            )}
          </S.FormItem>
          <Spacer />
          <S.FormItem>
            <InputTextV2
              readonly={true}
              name="login_password"
              value={password}
              label="アプリログインパスワード"
            />
            {!taxi.has_password && (
              <S.Buttons>
                <Button
                  primary={false}
                  backgroundColor="#a7813f"
                  textColor="#fff"
                  handleClick={() => {
                    generatePassword()
                  }}
                >
                  パスワード生成
                </Button>
              </S.Buttons>
            )}
          </S.FormItem>
          <Spacer />
          <Select
            required={true}
            name="delivery_reception_status"
            label="配送ステータス"
            options={deliveryReceptionStatusOption}
            defaultValue={taxi.delivery_reception_status}
          />
          <Spacer />
          <InputText
            required={true}
            name="name"
            defaultValue={taxi.name}
            label={I18n.t('generic.company_name')}
          />
          <Spacer />
          <InputText
            required={true}
            name="phone"
            defaultValue={taxi.address.phone}
            label={I18n.t('generic.phone')}
          />
          <Spacer />
          <Select
            required={true}
            name="notification_option"
            label={I18n.t('generic.notification')}
            options={notificationOptions}
            defaultValue={defaultNotificationValue}
            onChangeHandler={event => changeNotificationOption(event)}
          />
          <Spacer />
          <InputText
            required={selectedNotificationOption.notify_to_email}
            name="email"
            defaultValue={taxi.email}
            label={I18n.t('generic.email')}
          />
          <Spacer />
          <InputText
            required={selectedNotificationOption.notify_to_fax}
            name="fax"
            defaultValue={taxi.address.fax}
            label={I18n.t('generic.fax')}
          />
          <Spacer />
          <InputText
            required={true}
            name="postal_code1"
            defaultValue={taxi.address.postal_code1}
            label={I18n.t('generic.zipcode')}
          />
          <Spacer />
          <Select
            required={true}
            name="state1"
            label={I18n.t('generic.prefectures')}
            options={stateOptions}
            defaultValue={taxi.address.state1}
          />
          <Spacer />
          <InputText
            required={true}
            name="city1"
            defaultValue={taxi.address.city1}
            label={I18n.t('generic.city')}
          />
          <Spacer />
          <InputText
            required={false}
            name="other1_1"
            defaultValue={taxi.address.other1_1}
            label={I18n.t('generic.street1')}
          />
          <Spacer />
          <InputText
            required={false}
            name="other1_2"
            defaultValue={taxi.address.other1_2}
            label={I18n.t('generic.street2')}
          />
          <Spacer />
          <InputText
            required={false}
            name="notify_url"
            defaultValue={taxi.notify_url}
            label={I18n.t('generic.notify_url')}
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  display: flex;
`
S.Buttons = styled.div`
  display: flex;
  flex: 1;
  max-width: 200px;
  text-align: center;
  margin-left: 50px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 20px;
  }
`

export default AdminTaxiEdit
