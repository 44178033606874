import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IPost, IUser } from '../../../../core/interfaces'
import { postService } from '../../../../core/services'
import { BREAKPOINT_TABLET_SMALL } from '../../../../static/constants'
import { Button, Select, Spinner } from '../../../atoms'
import { Form } from '../../../molecules'

const USER_FIELDS = {
  user_id: 'user_id',
}

interface IErrors {
  [key: string]: string | null
}

interface IProps {
  post: IPost
  handleFormSubmit(initialValues: any, values: any): void
}

const HostUser: React.FC<IProps> = props => {
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState(false)
  const [users, setUsers] = React.useState<IUser[] | null>(null)
  const [errors, setErrors] = React.useState<IErrors>({})
  const currentUserID = props.post.user?.id.toString() || ''
  const handleUpdateForm = React.useCallback((updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }, [])
  const formatSelectOptions = options => {
    return options
      .filter(user => user.role.id === 2)
      .filter(user => !user.email.match('_deleted'))
      .map((option: { id: number; username: string }) => ({
        value: option.id,
        label: option.username,
      }))
  }

  const getUsers = async () => {
    const { users: initUsers } = await postService.getUsers()
    setUsers([{ value: '', label: '-' }, ...formatSelectOptions(initUsers)])
  }
  React.useEffect(() => {
    getUsers()
  }, [])
  return (
    <>
      {users === null ? (
        <S.Loading>
          <Spinner />
        </S.Loading>
      ) : (
        <Form
          fields={USER_FIELDS}
          handleUpdateForm={handleUpdateForm}
          handleSubmit={props.handleFormSubmit}
        >
          <ul>
            <S.FormItem>
              <Select
                required={true}
                name="user_id"
                label={I18n.t('meta.post.host_user')}
                options={users}
                defaultValue={currentUserID}
                error={errors.taxi}
              />
            </S.FormItem>
          </ul>
          <S.Footer className="Editor_Footer">
            <Button primary={true} disabled={!isSubmitEnabled}>
              {I18n.t('generic.update')}
            </Button>
          </S.Footer>
        </Form>
      )}
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.FormItem = styled.div`
  & + & {
    margin-top: 16px;
  }
`
S.Footer = styled.div`
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-top: 20px;
  }
`
S.Loading = styled.div`
  margin: 32px 0;
`

export default HostUser
