import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IRole, IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService, reservationService } from '../../../../core/services'
import { Select } from '../../../atoms'
import { EditTable } from '../../../organisms'
import AdminLayoutIndex from '../_layouts/'

interface IProps {
  role?: IRole
  reservations: {
    reservations: IJsonResponse
  }
  search_params?: any
  taxies?: any
}

const options = { scope: 'activerecord.attributes.reservation' }
const COLUMNS = [
  {
    name: I18n.t('id', options),
    field: 'id',
  },
  {
    name: I18n.t('user_id', options),
    field: record => <span>{record.user_id}</span>,
  },
  {
    name: I18n.t('user_name', options),
    field: record => <span>{record.username}</span>,
  },
  {
    name: I18n.t('post_id', options),
    field: record => <span>{record.post.id}</span>,
  },
  {
    name: I18n.t('taken_post', options),
    field: record => <span>{record.post.name}</span>,
  },
  {
    name: I18n.t('taken_taxi', options),
    field: record => <span>{record.taxi_name}</span>,
  },
  {
    name: I18n.t('delivery_time', options),
    field: 'specified_delivery_time_at',
  },
  {
    name: I18n.t('approve_status', options),
    field: record => {
      const label =
        record.workflow_state !== 'approved'
          ? I18n.t(`enums.reservation.workflow_state.${record.workflow_state}`)
          : I18n.t('enums.reservation.workflow_state.post_approved')
      return <span>{label}</span>
    },
  },
  {
    name: I18n.t('delivery_status', options),
    field: record => <span>{record.delivery_status_i18n}</span>,
  },
  {
    name: I18n.t('price', options),
    field: 'price',
  },
  {
    name: I18n.t('actual_taxi_fare', options),
    field: 'actual_taxi_fare',
  },
  {
    name: I18n.t('used_campaign', options),
    field: 'used_campaign',
  },
  {
    name: I18n.t('used_coupon', options),
    field: 'used_coupon',
  },
  {
    name: I18n.t('coupon_code', options),
    field: 'coupon_code',
  },
  {
    name: I18n.t('paid_at', options),
    field: 'paid_at',
  },
  {
    name: I18n.t('canceled_at', options),
    field: 'canceled_at',
  },
  {
    name: I18n.t('created_at', options),
    field: 'created_at',
  },
  {
    name: I18n.t('updated_at', options),
    field: 'updated_at',
  },
  {
    name: I18n.t('authorized_at', options),
    field: 'authorized_at',
  },
]

const FIELDS = {
  taxi: 'taxi',
}

const Index: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.reservations.reservations)
  const isTaxi = props.role?.id === 4

  const formatSelectOptions = selectOptions => {
    return selectOptions.map((option: { id: number; name: string }) => ({
      value: option.id,
      label: option.name,
    }))
  }

  const taxiList = [{ value: 'all', label: 'すべて' }, ...formatSelectOptions(props.taxies)]
  const [taxies, setTaxies] = React.useState(taxiList)

  return (
    <AdminLayoutIndex
      role={props.role}
      model="reservation"
      heading={I18n.t('activerecord.models.reservation')}
      main={
        <EditTable
          editable={!isTaxi}
          columns={COLUMNS}
          records={data}
          pagination={pagination}
          getEditLink={!isTaxi && (id => `/host/reservations/${id}`)}
          formSearch={
            !isTaxi && (
              <>
                <Select
                  required={false}
                  name="taxi"
                  label="受注タクシー会社"
                  options={taxies}
                  defaultValue={props.search_params.taxi || 'all'}
                  width={300}
                />
              </>
            )
          }
          formFields={FIELDS}
        />
      }
    />
  )
}

export default Index
