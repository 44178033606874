import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IRole, IWindow } from '../../../core/interfaces'
import {
  BREAKPOINT_TABLET_LARGE,
  COLORS,
  THEME_COLOR_VARIABLE_NAME,
} from '../../../static/constants'

export type ActiveTypes =
  | 'post'
  | 'taxi'
  | 'category'
  | 'tag'
  | 'user'
  | 'reservation'
  | 'contact'
  | 'identification'
  | 'coupon'
  | 'setting'
  | 'item_category'
  | 'report'
  | 'info'
  | 'bank_account'
  | 'taxi_bank_account'

interface IProps {
  active: ActiveTypes
  role?: IRole
}

const options = { scope: 'activerecord.models' }
const Nav: React.FC<IProps> = ({ active, role }) => {
  const isAdmin = role?.id === 3
  const isTaxi = role?.id === 4

  return (
    <S.Nav>
      <S.List>
        {isTaxi ? (
          <>
            <S.ListItem active={active === 'reservation'}>
              <a href="/admin/reservations">{I18n.t('reservation', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'report'}>
              <a href="/admin/reports">{I18n.t('report', options)}</a>
            </S.ListItem>
          </>
        ) : (
          <>
            <S.ListItem active={active === 'post'}>
              <a href="/admin/posts">{I18n.t('post', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'taxi'}>
              <a href="/admin/taxies">{I18n.t('taxi', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'category'}>
              <a href="/admin/categories">{I18n.t('category', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'item_category'}>
              <a href="/admin/item_categories">{I18n.t('item_category', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'tag'}>
              <a href="/admin/tags">{I18n.t('tag', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'user'}>
              <a href="/admin/users">{I18n.t('user', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'reservation'}>
              <a href="/admin/reservations">{I18n.t('reservation', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'contact'}>
              <a href="/admin/contacts">{I18n.t('contact', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'coupon'}>
              <a href="/admin/coupons">{I18n.t('coupon', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'info'}>
              <a href="/admin/infos">{I18n.t('info', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'report'}>
              <a href="/admin/reports">{I18n.t('report', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'setting'}>
              <a href="/admin/setting">{I18n.t('setting', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'bank_account'}>
              <a href="/admin/bank_accounts">{I18n.t('bank_account', options)}</a>
            </S.ListItem>
            <S.ListItem active={active === 'taxi_bank_account'}>
              <a href="/admin/taxies/bank_accounts">{I18n.t('taxi_bank_account', options)}</a>
            </S.ListItem>
          </>
        )}
      </S.List>
    </S.Nav>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Nav = styled.div`
  width: 240px;
  height: 100%;
  border-bottom: solid 1px ${COLORS.Border};
  box-shadow: 0 1px 3px 0 rgba(21, 27, 38, 0.1);
  background-color: #fff;
  z-index: 500;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    width: 100%;
    height: 40px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    white-space: nowrap;
  }
`

S.List = styled.ul`
  padding: 16px 0;
  @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
    height: inherit;
    padding: 0;
    display: -webkit-box;
  }
`

S.ListItem = styled.li<{ active: ActiveTypes; disabled?: boolean }>`
  > a {
    display: block;
    padding: 8px 20px;
    cursor: pointer;
    font-size: 14px;
    color: ${COLORS.Text};

    &:hover {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      display: inline-block;
      height: inherit;
    }
  }

  ${({ active }) =>
    active &&
    `
    border-right: solid 3px var(${THEME_COLOR_VARIABLE_NAME});
    font-weight: bold;

    > a {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }

    @media (max-width: ${BREAKPOINT_TABLET_LARGE}px) {
      border-right: none;
      border-bottom: solid 2px var(${THEME_COLOR_VARIABLE_NAME});
    }
  `}

  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.6;
    cursor: not-allowed;
  `}

  ${({ active, disabled }) =>
    !active &&
    !disabled &&
    `
    :hover {
      color: var(${THEME_COLOR_VARIABLE_NAME});
    }
  `}
`

export default Nav
