import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import { infoService } from '../../core/services'
import { BREAKPOINT_TABLET_SMALL, COLORS } from '../../static/constants'

interface IContents {
  createdAt: string
  id: string
  info_image: {
    image_url: string
  }
  publishedAt: string
  title: string
  body: string
  updatedAt: string
  viewDate: string
  visible: boolean
  content: string
}

const TopNews: React.FC<{}> = () => {
  const [infos, setInfos] = React.useState<IContents[]>([])

  React.useEffect(() => {
    infoService
      .getInfos()
      .then(data => setInfos(data?.infos ?? []))
      .catch(console.error)
  }, [])

  return (
    <S.TopNews>
      <S.Title>キャンペーン・お知らせ</S.Title>
      <S.Contents>
        {infos.length > 0 ? (
          infos.map(content => (
            <S.Content key={content.id} href={`/info/${content.id}`} title={content.title}>
              <img src={content.info_image?.image_url} alt="" />
              <S.ContentMain>
                <h2>{content.title}</h2>
                <p>
                  {content.content.replace(/<("[^"]*"|'[^']*'|[^'">])*>/g, '').substring(0, 40) +
                    '…'}
                </p>
                {/* <div dangerouslySetInnerHTML={{ __html: content.content }} /> */}
              </S.ContentMain>
            </S.Content>
          ))
        ) : (
          <S.NoContents>お知らせはありません</S.NoContents>
        )}
      </S.Contents>
    </S.TopNews>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.TopNews = styled.section`
  width: 100%;
  max-width: 1360px;
  margin: 24px auto;
  padding: 0 16px;
`

S.Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
`

S.NoContents = styled.h2`
  font-size: 18px;
  font-weight: normal;
  margin: 24px 0;
  padding: 0 20px;
`

S.Contents = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  margin: 0 -20px;
`

S.Content = styled.a`
  display: flex;
  width: 50%;
  padding: 10px 20px;
  border-bottom: 1px solid ${COLORS.Border};
  color: #333;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
  }

  > img {
    width: 160px;
    height: 120px;
    object-fit: cover;
  }
`

S.ContentMain = styled.div`
  margin-left: 20px;
  width: 50%;
  word-break: break-all;
  h2 {
    font-size: 18px;
  }

  p {
    margin-top: 25px;
    font-size: 16px;
  }
`

export default TopNews
