import * as React from 'react'
import I18n from '../../../../../core/i18n'
import { IJsonResponse } from '../../../../../core/JsonApiSerializer'
import { adminService } from '../../../../../core/services'
import { InputText, Select, SelectWithSearch, Spacer } from '../../../../atoms'
import AdminLayoutEdit from '../../_layouts/edit'

interface IProps {
  bank_account: IJsonResponse
  banks: any
}

const FIELDS = {
  id: 'id',
  bank_id: 'bank_id',
  account_type: 'account_type',
  number: 'number',
  name: 'name',
  branch_code: 'branch_code',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('taxi_bank_account', { scope: 'activerecord.models' }),
})

const accountTypeOptions = [
  { value: 'saving', label: '普通' },
  { value: 'checking', label: '当座' },
]

const AdminTaxiesBankAccountEdit: React.FC<IProps> = props => {
  const { data: bank_account } = adminService.getDataFromJson(props.bank_account)
  const formatBankOptions = options => {
    return options.map((option: { id: number; name: string; code: string }) => ({
      value: option.id,
      label: `${option.name}/${option.code}`,
      code: option.code,
    }))
  }

  const bankList = formatBankOptions(props.banks)
  const [banks, setBanks] = React.useState(bankList)

  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { updatedBankAccount, flush } = await adminService.updateTaxiBankAccount(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/taxies/bank_accounts'
  }, [])

  return (
    <AdminLayoutEdit
      model="taxi_bank_account"
      title={title}
      fields={FIELDS}
      indexLink="/admin/taxies/bank_accounts"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={bank_account.id} label="ID" />
          <Spacer />
          <InputText
            readonly={true}
            name="post_id"
            defaultValue={bank_account.taxi.id}
            label="タクシーID"
          />
          <Spacer />
          <InputText
            readonly={true}
            name="post_name"
            defaultValue={bank_account.taxi.name}
            label="タクシー会社名"
          />
          <Spacer />
          <SelectWithSearch
            options={banks}
            defaultValue={banks.find(bank => bank.code === bank_account.bank_code)}
            label="銀行名"
            name="bank_id"
            required={true}
          />
          <Spacer />
          <InputText
            required={true}
            name="branch_code"
            defaultValue={bank_account.branch_code}
            label="支店コード"
          />
          <Spacer />
          <Select
            required={true}
            name="account_type"
            label="口座種別"
            options={accountTypeOptions}
            defaultValue={bank_account.account_type}
          />
          <Spacer />
          <InputText
            required={true}
            name="number"
            defaultValue={bank_account.number}
            label="口座番号"
          />
          <Spacer />
          <InputText
            required={true}
            name="name"
            defaultValue={bank_account.name}
            label="口座名義"
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminTaxiesBankAccountEdit
