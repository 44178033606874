import * as React from 'react'
import I18n from '../../../../core/i18n'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { InputText, Spacer } from '../../../atoms'
import AdminLayoutEdit from '../_layouts/edit'

interface IProps {
  item_category: IJsonResponse
}

const FIELDS = {
  id: 'id',
  name: 'name',
  position: 'position',
}

const title = I18n.t('admin.edit', {
  model: I18n.t('item_category', { scope: 'activerecord.models' }),
})

const AdminItemCategoryEdit: React.FC<IProps> = props => {
  const { data: item_category } = adminService.getDataFromJson(props.item_category)
  const handleSubmit = React.useCallback(async (initialValues, values) => {
    const { updatedItemCategory, flush } = await adminService.updateItemCategory(values)
    // TODO 遷移先でのFlush
    location.href = '/admin/item_categories'
  }, [])

  return (
    <AdminLayoutEdit
      model="item_category"
      title={title}
      fields={FIELDS}
      indexLink="/admin/item_categories"
      formItems={
        <>
          <InputText readonly={true} name="id" defaultValue={item_category.id} label="ID" />
          <Spacer />
          <InputText required={true} name="name" defaultValue={item_category.name} label="Name" />
          <Spacer />
          <InputText
            required={true}
            name="position"
            defaultValue={item_category.position}
            label="Position"
          />
        </>
      }
      handleSubmit={handleSubmit}
    />
  )
}

export default AdminItemCategoryEdit
