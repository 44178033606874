import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../core/i18n'
import { IUser } from '../../../core/interfaces'
import { userService } from '../../../core/services'
import * as constants from '../../../static/constants'
import { Button, InputText, InputTextArea, Panel } from '../../atoms'
import { Form } from '../../molecules'

interface IValues {
  name: string
  email: string
  subject: string
  body: string
}

interface IProps {
  user: IUser
}

const FIELDS = {
  name: 'name',
  email: 'email',
  subject: 'subject',
  body: 'body',
}

const useContact = (userId: number) => {
  const [confirmationValues, setConfirmationValues] = React.useState(null)
  const [processing, setProcessing] = React.useState(null)
  const [completed, setCompleted] = React.useState(false)
  const setValues = (initialValues, submitValues) => {
    setConfirmationValues(submitValues)
  }
  const handleSubmit = React.useCallback(async () => {
    if (!confirmationValues) {
      return
    }
    const params = {
      ...confirmationValues,
      user_id: userId,
    }
    setProcessing(true)
    await userService.createContact(params)
    setCompleted(true)
  }, [confirmationValues])
  const handleOnPrev = () => setConfirmationValues(null)
  return { confirmationValues, handleOnPrev, setValues, handleSubmit, completed, processing }
}

const Contacts: React.FC<IProps> = props => {
  const {
    confirmationValues,
    handleOnPrev,
    setValues,
    handleSubmit,
    completed,
    processing,
  } = useContact(props.user?.id)
  return (
    <S.Contacts>
      {completed ? (
        <Panel>
          <S.Completed>{I18n.t('contact.completed')}</S.Completed>
        </Panel>
      ) : (
        <Panel title={I18n.t('generic.contact')}>
          {!confirmationValues && (
            <Form fields={FIELDS} handleSubmit={setValues}>
              <ContactsFormBody user={props.user} />
            </Form>
          )}

          {confirmationValues && (
            <ContactsConfirmation
              values={confirmationValues}
              presence={confirmationValues ? true : false}
              processing={processing}
              handleOnPrev={handleOnPrev}
              handleSubmit={handleSubmit}
            />
          )}
        </Panel>
      )}
    </S.Contacts>
  )
}

const ContactsFormBody: React.FC<{ user: IUser }> = props => {
  return (
    <div>
      <S.Caution>
        ※ 個人データの削除を希望する場合は下記よりお問い合わせください。
        <br />※ 本人確認を行うために、追加の情報確認を行うことがあります。
      </S.Caution>
      <S.FormItem>
        <InputText
          name="name"
          label={I18n.t('generic.name')}
          defaultValue={props.user?.username}
          required={true}
        />
      </S.FormItem>
      <S.FormItem>
        <InputText
          name="email"
          label={I18n.t('generic.email')}
          defaultValue={props.user?.email}
          required={true}
        />
      </S.FormItem>
      <S.FormItem>
        <InputText
          name="subject"
          label={I18n.t('contact.subject')}
          defaultValue=""
          required={true}
        />
      </S.FormItem>
      <S.FormItem>
        <InputTextArea name="body" label={I18n.t('contact.body')} defaultValue="" required={true} />
      </S.FormItem>
      <S.Buttons>
        <Button primary={true}>{I18n.t('generic.next')}</Button>
      </S.Buttons>
    </div>
  )
}

interface IContactsConfirmationProps {
  values: null | IValues
  presence: boolean
  processing: boolean
  handleOnPrev(): void
  handleSubmit(): Promise<void>
}

const ContactsConfirmation: React.FC<IContactsConfirmationProps> = props => {
  return (
    <div style={{ display: props.presence ? 'block' : 'none' }}>
      <S.Confirmation>{I18n.t('contact.confirmation')}</S.Confirmation>
      {props.values && (
        <dl>
          <dt>{I18n.t('generic.name')}</dt>
          <dd>{props.values.name}</dd>
          <dt>{I18n.t('generic.email')}</dt>
          <dd>{props.values.email}</dd>
          <dt>{I18n.t('contact.subject')}</dt>
          <dd>{props.values.subject}</dd>
          <dt>{I18n.t('contact.body')}</dt>
          <dd>{props.values.body}</dd>
        </dl>
      )}
      <S.Buttons>
        <Button handleClick={props.handleOnPrev}>{I18n.t('generic.back')}</Button>
        <Button primary={true} handleClick={props.handleSubmit} disabled={props.processing}>
          {I18n.t('generic.submit')}
        </Button>
      </S.Buttons>
    </div>
  )
}
const S: { [key: string]: AnyStyledComponent } = {}
S.Contacts = styled.div`
  background-color: #f6f8f9;
  height: calc(100vh - ${constants.HEADER_HEIGHT}px);
  padding: 24px 12px;
  overflow: scroll;

  dl {
    display: flex;
    flex-wrap: wrap;
  }

  dt {
    width: 240px;
    margin-top: 24px;
    font-weight: bold;
  }

  dd {
    width: calc(100% - 240px);
    margin-top: 24px;
    @media (max-width: ${constants.BREAKPOINT_TABLET_SMALL}px) {
      width: 100%;
      margin-top: 8px;
    }
  }
`

S.Caution = styled.div`
  margin-bottom: 24px;
  color: #ff0000;
  font-size: 14px;
  font-weight: bold;
`

S.FormItem = styled.div`
  & + & {
    margin-top: 24px;
  }
`
S.Completed = styled.p`
  text-align: center;
`
S.Confirmation = styled.p`
  text-align: center;
`
S.Buttons = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin: 48px 0 0;

  .Button[type='submit'] {
    display: block;
    width: 160px;
  }

  .Button[type='button'] {
    position: absolute;
    left: 0;
    top: 0;
  }
`
export default Contacts
