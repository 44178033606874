import { AxiosInstance } from 'axios'
import { IJsonApiSerializer } from '../JsonApiSerializer'

class AdminService {
  public httpClient: AxiosInstance
  public serializer: IJsonApiSerializer

  constructor(httpClient: AxiosInstance, serializer: IJsonApiSerializer) {
    this.httpClient = httpClient
    this.serializer = serializer
  }

  public getDataFromJson(JsonData) {
    const data = this.serializer.parseResourceData(JsonData, JsonData.data)
    const pagination = { ...JsonData.meta }

    return { data, pagination }
  }

  public getIdentificationsFromJson(data) {
    const identifications = this.serializer.parseResourceData(data, data.data)
    const pagination = { ...data.meta }

    return { identifications, pagination }
  }

  public async approveIdentification(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/approve`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flush: data.flush }
  }

  public async declineIdentification(id) {
    const { data } = await this.httpClient.put(`/admin/api/identifications/${id}/decline`)
    const identification = this.serializer.parseResourceData(
      data.identification,
      data.identification.data
    )

    return { identification, flush: data.flush }
  }

  public async createCategory(category, image, icon) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('category[category_image_attributes][image]', image.file)
    }
    if (icon?.file) {
      formData.append('category[category_icon_attributes][image]', icon.file)
    }
    Object.keys(category).forEach(key => {
      formData.append(`category[${key}]`, category[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/categories`, formData)
    const createdCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { createdCategory, flush: data.flush }
  }

  public async updateCategory(category, image, icon) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('category[category_image_attributes][image]', image.file)
    }
    if (icon?.file) {
      formData.append('category[category_icon_attributes][image]', icon.file)
    }
    Object.keys(category).forEach(key => {
      formData.append(`category[${key}]`, category[key])
    })

    const { data } = await this.httpClient.patch(`/admin/api/categories/${category.id}`, formData)
    const updatedCategory = this.serializer.parseResourceData(data.category, data.category.data)

    return { updatedCategory, flush: data.flush }
  }

  public async deleteCategory(id) {
    const { data } = await this.httpClient.delete(`/admin/api/categories/${id}`)

    return { flush: data.flush }
  }

  public async createTag(tag) {
    const { data } = await this.httpClient.post('/admin/api/tags/', { tag })
    const createdTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { createdTag, flush: data.flush }
  }

  public async updateTag(tag) {
    const { data } = await this.httpClient.patch(`/admin/api/tags/${tag.id}`, { tag })
    const updatedTag = this.serializer.parseResourceData(data.tag, data.tag.data)

    return { updatedTag, flush: data.flush }
  }

  public async deleteTag(id) {
    return this.httpClient.delete(`/admin/api/tags/${id}`)
  }

  public async createPost(post) {
    const { data } = await this.httpClient.post(`/admin/api/posts`, post)
    const createdPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { createdPost, flush: data.flush }
  }

  public async updatePost(post) {
    const { data } = await this.httpClient.patch(`/admin/api/posts/${post.id}`, { post })
    const updatedPost = this.serializer.parseResourceData(data.post, data.post.data)

    return { updatedPost, flush: data.flush }
  }

  public async deletePost(id) {
    return await this.httpClient.delete(`/admin/api/posts/${id}`)
  }

  public async createUser(user) {
    const { data } = await this.httpClient.post('/admin/api/users', user)
    const createdUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { createdUser, flush: data.flush }
  }

  public async updateUser(user) {
    const { data } = await this.httpClient.patch(`/admin/api/users/${user.user.id}`, user)
    const updatedUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { updatedUser, flush: data.flush }
  }

  public async deleteUser(id) {
    const {
      data: { flush },
    } = await this.httpClient.delete(`/admin/api/users/${id}`)

    return { flush }
  }

  public async createTaxi(params) {
    const { data } = await this.httpClient.post(`/admin/api/taxies`, params)
    const createdTaxi = this.serializer.parseResourceData(data.taxi, data.taxi.data)

    return { createdTaxi, flush: data.flush }
  }

  public async updatetaxi(params) {
    const { data } = await this.httpClient.patch(`/admin/api/taxies/${params.taxi.id}`, params)
    const updatedTaxi = this.serializer.parseResourceData(data.taxi, data.taxi.data)

    return { updatedTaxi, flush: data.flush }
  }

  public async deleteTaxi(id) {
    const { data } = await this.httpClient.delete(`/admin/api/taxies/${id}`)

    return { flush: data.flush }
  }

  public async becomeUser(id) {
    const { data } = await this.httpClient.post(`/admin/api/users/${id}/become`)

    const becameUser = this.serializer.parseResourceData(data.user, data.user.data)

    return { becameUser, flush: data.flush }
  }

  public async createCoupon(coupon) {
    const { data } = await this.httpClient.post(`/admin/api/coupons`, { coupon })
    const createdCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { createdCoupon, flush: data.flush }
  }

  public async updateCoupon(coupon) {
    const { data } = await this.httpClient.patch(`/admin/api/coupons/${coupon.id}`, {
      coupon,
    })
    const updatedCoupon = this.serializer.parseResourceData(data.coupon, data.coupon.data)

    return { updatedCoupon, flush: data.flush }
  }

  public async deleteCoupon(id) {
    const { data } = await this.httpClient.delete(`/admin/api/coupons/${id}`)

    return { flush: data.flush }
  }

  public async createInfo(info, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('info[info_image_attributes][image]', image.file)
    }
    Object.keys(info).forEach(key => {
      formData.append(`info[${key}]`, info[key])
    })

    const { data } = await this.httpClient.post(`/admin/api/infos`, formData)
    const createdInfo = this.serializer.parseResourceData(data.info, data.info.data)

    return { createdInfo, flush: data.flush }
  }

  public async updateInfo(info, image) {
    const formData = new FormData()
    if (image?.file) {
      formData.append('info[info_image_attributes][image]', image.file)
    }
    Object.keys(info).forEach(key => {
      formData.append(`info[${key}]`, info[key])
    })
    const { data } = await this.httpClient.patch(`/admin/api/infos/${info.id}`, formData)
    const updatedInfo = this.serializer.parseResourceData(data.info, data.info.data)
    return { updatedInfo, flush: data.flush }
  }

  public async deleteInfo(id) {
    const { data } = await this.httpClient.delete(`/admin/api/infos/${id}`)

    return { flush: data.flush }
  }

  public async getPosts() {
    const { data } = await this.httpClient.get('/admin/api/posts')
    const postList = this.serializer.parseResourceData(data.posts, data.posts.data)

    return { postList }
  }

  public async createItemCategory(item_category) {
    const { data } = await this.httpClient.post(`/admin/api/item_categories`, { item_category })
    const createdItemCategory = this.serializer.parseResourceData(
      data.item_category,
      data.item_category.data
    )

    return { createdItemCategory, flush: data.flush }
  }

  public async updateItemCategory(item_category) {
    const { data } = await this.httpClient.patch(`/admin/api/item_categories/${item_category.id}`, {
      item_category,
    })
    const updatedItemCategory = this.serializer.parseResourceData(
      data.item_category,
      data.item_category.data
    )

    return { updatedItemCategory, flush: data.flush }
  }

  public async deleteItemCategory(id) {
    const { data } = await this.httpClient.delete(`/admin/api/item_categories/${id}`)

    return { flush: data.flush }
  }

  public async getBanks() {
    const { data } = await this.httpClient.get('/admin/api/banks')
    const bankList = this.serializer.parseResourceData(data.banks, data.banks.data)

    return { bankList }
  }

  public async createBankAccount(bank_account) {
    const { data } = await this.httpClient.post(`/admin/api/bank_accounts`, { bank_account })
    const createdBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { createdBankAccount, flush: data.flush }
  }

  public async updateBankAccount(bank_account) {
    const { data } = await this.httpClient.patch(`/admin/api/bank_accounts/${bank_account.id}`, {
      bank_account,
    })
    const updatedBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { updatedBankAccount, flush: data.flush }
  }

  public async deleteBankAccount(id) {
    const { data } = await this.httpClient.delete(`/admin/api/bank_accounts/${id}`)

    return { flush: data.flush }
  }

  public async createTaxiBankAccount(bank_account) {
    const { data } = await this.httpClient.post(`/admin/api/taxies/bank_accounts`, { bank_account })
    const createdBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { createdBankAccount, flush: data.flush }
  }

  public async updateTaxiBankAccount(bank_account) {
    const { data } = await this.httpClient.patch(
      `/admin/api/taxies/bank_accounts/${bank_account.id}`,
      {
        bank_account,
      }
    )
    const updatedBankAccount = this.serializer.parseResourceData(
      data.bank_account,
      data.bank_account.data
    )

    return { updatedBankAccount, flush: data.flush }
  }

  public async deleteTaxiBankAccount(id) {
    const { data } = await this.httpClient.delete(`/admin/api/taxies/bank_accounts/${id}`)

    return { flush: data.flush }
  }
}

export default AdminService
