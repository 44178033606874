import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../core/i18n'
import { IWindow } from '../../../../core/interfaces'
import { IJsonResponse } from '../../../../core/JsonApiSerializer'
import { adminService } from '../../../../core/services'
import { Button } from '../../../atoms'
import { EditTableInfo } from '../../../organisms'
import AdminLayoutIndex from '../_layouts'

declare var window: IWindow

interface IProps {
  infos: {
    infos: IJsonResponse
  }
}

const COLUMNS = [
  {
    name: I18n.t('generic.id'),
    field: 'id',
    required: true,
  },
  {
    name: I18n.t('generic.title'),
    field: 'title',
    required: false,
  },
  // {
  //   name: I18n.t('generic.open_reservation_at'),
  //   field: 'open_reservation_at',
  //   required: false,
  // },
  // {
  //   name: I18n.t('generic.close_reservation_at'),
  //   field: 'close_reservation_at',
  //   required: false,
  // },
  // {
  //   name: I18n.t('generic.opened'),
  //   field: 'opened',
  //   required: false,
  // },
  {
    name: I18n.t('generic.created_at'),
    field: 'created_at',
    required: false,
  },
  {
    name: I18n.t('generic.updated_at'),
    field: 'updated_at',
    required: false,
  },
]

const AdminInfoIndex: React.FC<IProps> = props => {
  const { data, pagination } = adminService.getDataFromJson(props.infos.infos)
  const [infos, setInfos] = React.useState(data)
  const handleDelete = React.useCallback(
    async id => {
      const { flush } = await adminService.deleteInfo(id)
      window.flashMessages.addMessage({
        text: flush.message,
        type: flush.type,
      })
      window.globalModal.closeModal()
      setInfos(infos.filter(info => info.id !== id))
      location.reload()
    },
    [infos]
  )

  return (
    <AdminLayoutIndex
      model="info"
      heading={
        <S.Heading>
          <span>{I18n.t('activerecord.models.info')}</span>
          <Button small={true}>
            <a href="/admin/infos/new">{I18n.t('generic.create_new')}</a>
          </Button>
        </S.Heading>
      }
      main={
        <EditTableInfo
          editable={true}
          columns={COLUMNS}
          records={infos}
          pagination={pagination}
          handleDelete={handleDelete}
          getEditLink={id => `/admin/infos/${id}/edit`}
        />
      }
    />
  )
}

const S: { [key: string]: AnyStyledComponent } = {}

S.Heading = styled.div`
  .Button {
    margin-left: 24px;
  }
`

export default AdminInfoIndex
