export function getOS() {
  const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera

  if (/android|Linux/i.test(userAgent)) {
    return "android"
  } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return "iOS"
  } else {
    return "unknown"
  }
}

