import React from 'react'
import styled from 'styled-components'
import I18n from '../../../core/i18n'
import { Button } from '../../atoms'

interface IMessage {
  id: number
  sender_id: number
  sender_name: string
  sender_avatar_url: string
  formatted_sent_time: string
  file_attached: boolean
  file_url: string
  subject: string
  body: string
}

interface IState {
  fetching: boolean
  showModal: boolean
  messages: IMessage[]
  messageAttachFile: any
  messageAttachFileUrl: any
  errors: { [key: string]: any }
  isSubmitEnabled: boolean
}
interface IProps {
  post?: any
  conversations?: any
  messages?: IMessage[]
  post_images?: any[]
  user?: any
  available_state_to: boolean
}

class ConversationIndex extends React.Component<IProps, IState> {
  constructor(props) {
    super(props)
  }

  public render() {
    return (
      <ConversationIndexWrapper>
        <table>
          <thead>
            <tr>
              <th>{I18n.t('generic.user')}</th>
              <th>{I18n.t('conversation.last_message')}</th>
              <th>{I18n.t('generic.updated_at')}</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {this.props.conversations.map(conversation => (
              <tr key={conversation.id}>
                <td>{conversation.last_message.sender_username}</td>
                <td>{conversation.last_message.body}</td>
                <td>{conversation.updated_at}</td>
                <td>
                  <Button>
                    <a href={`/conversations/${conversation.slug}`}>
                      {I18n.t('generic.show_detail')}
                    </a>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ConversationIndexWrapper>
    )
  }
}

const ConversationIndexWrapper = styled.div`
  max-width: 1040px;
  margin: 0 auto;
  padding: 24px 12px 0;

  table {
    width: 100%;

    th,
    td {
      vertical-align: middle;
      max-width: 400px;
      padding: 8px 12px;
    }
  }
`

export default ConversationIndex
