import * as moment from 'moment'
import { any } from 'prop-types'
import * as React from 'react'
import styled, { AnyStyledComponent } from 'styled-components'
import I18n from '../../../../../core/i18n'
import { IItemCategory, IOptionItem, IPostItem, IPostOption } from '../../../../../core/interfaces'
import { postItemService } from '../../../../../core/services'
import { BREAKPOINT_TABLET_SMALL, THEME_COLOR_VARIABLE_NAME } from '../../../../../static/constants'
import { Button, CheckBox, InputText, InputTextArea, Select } from '../../../../atoms'
import { DropZoneUploader } from '../../../../atoms'
import { Form } from '../../../../molecules'
import { FlashMessage } from '../../../../organisms'
import { FileSelectUploader } from '../../../../organisms'
import { OptionForm } from '../../../../organisms'
import { PostContext } from './'
import buildFormInputName from './functions/buildFormInputName'
interface IWindow {
  flashMessages: FlashMessage
  addEventListener: any
  removeEventListener: any
}
declare var window: IWindow

{
  /* こちらに含まれた Name しか submit されない */
}
const MENU_FIELDS = {
  name: 'name',
  description: 'description',
  retail_price: 'retail_price',
  quantity: 'quantity',
  is_quantity_limited: 'is_quantity_limited',
  item_category_id: 'item_category_id',
}
interface IProps {
  item: IPostItem
  postId: number
  deliveryRate: number
  itemCategories: IItemCategory[]
  handleBack(): void
  updateItem(item: IPostItem): void
}
interface IErrors {
  [key: string]: string | null
}
interface IImage {
  image_url: string
  file?: any
}
interface IOptionFormData {
  title: string
  description: string
  require: boolean
  multiple: boolean
}

const PostItemEdit: React.FC<IProps> = props => {
  const { items, setItems } = React.useContext(PostContext)
  const [isSubmitEnabled, setIsSubmitEnabled] = React.useState<boolean>(false)
  const [limitedItem, setLimitedItem] = React.useState<boolean>(props.item.is_quantity_limited)
  const quantityRef = React.createRef<HTMLInputElement>()
  const [errors, setErrors] = React.useState<IErrors>({})
  const [image, setImage] = React.useState<IImage>(
    props.item.image_url
      ? {
          image_url: props.item.image_url,
        }
      : null
  )

  const [menuFields, setMenuFields] = React.useState<any>(MENU_FIELDS)
  {
    /* MENU_FIELDS 生成処理 */
  }
  buildFormInputName(props.item, menuFields)

  const [priceWithDeliveryFee, setPriceWithDeliveryFee] = React.useState<string>(
    props.item.wholesale_price ? String(props.item.wholesale_price) : '0'
  )

  const onDropHandler = addedImages => {
    addedImages.forEach(addedImage => {
      const fileReader = new FileReader()
      fileReader.onloadend = (event: any) => {
        setImage({
          file: addedImage,
          image_url: event.target.result,
        })
      }
      fileReader.readAsDataURL(addedImage)
    })
  }

  const setQuantityLimitedStatus = e => {
    setLimitedItem(e.target.checked)
    if (e.target.checked === true) {
      quantityRef.current.focus()
    }
  }

  const handlePriceChange = e => {
    const newPrice = Math.ceil(Number(e.currentTarget.value * props.deliveryRate))
    setPriceWithDeliveryFee(newPrice ? String(newPrice) : '-')
  }

  const handleUpdateForm = (updatedErrors, updatedIsSubmitEnabled) => {
    setErrors(updatedErrors)
    setIsSubmitEnabled(updatedIsSubmitEnabled)
  }

  const customValidation = (key, value) => {
    let message = null
    if (key === 'quantity' && value < props.item.total_ordered_count && limitedItem === true) {
      message = I18n.t('post.max_quantity_must_be_bigger_than_orders_count')
    }
    message = setChildFormErrorMessages(key, value, message)
    return message
  }

  const setChildFormErrorMessages = (key, value, message) => {
    if (!key.match(/post_options_attributes/)) {
      return message
    }

    const match_str = key.match(/^\D+(\d+)\D+(\d+)\]\[(\D+)\]$/)
    if (match_str == null) {
      return message
    }

    const inputName = `post_options_attributes[${match_str[1]}][option_items_attributes][${match_str[2]}][is_quantity_limited]`
    const orderedCountInput = `post_options_attributes[${match_str[1]}][option_items_attributes][${match_str[2]}][total_ordered_count]`
    const limitedOptionItem = document.getElementsByName(inputName)[0]
    const orderedCount = document.getElementsByName(orderedCountInput)[0]

    if (
      match_str[3] === 'quantity' &&
      value < Number((orderedCount as HTMLInputElement).value) &&
      (limitedOptionItem as HTMLInputElement).checked
    ) {
      message = I18n.t('post.max_quantity_must_be_bigger_than_orders_count')
    }

    return message
  }

  const imageToUpload = React.useRef(image)
  React.useEffect(() => {
    imageToUpload.current = image
  }, [image])

  const handleItemFormSubmit = async (initialValues, values) => {
    const params = { ...values }
    const currentValues = {}
    Object.keys(values).forEach(key => {
      currentValues[key] = String(values[key])
    })

    if (params.retail_price) {
      params.retail_price = Number(String(params.retail_price).replace(/[^0-9]/g, ''))
    }
    if (params.quantity) {
      params.quantity = Number(String(params.quantity).replace(/[^0-9]/g, ''))
    }
    if (props.item.id) {
      params.id = props.item.id
    }
    if (!params.id) {
      const { edit_post_path, postItem } = await postItemService.create(
        params,
        imageToUpload.current,
        props.postId
      )
      location.href = edit_post_path
    } else {
      const { edit_post_path, flush, postItem } = await postItemService.update(
        params,
        imageToUpload.current,
        props.postId
      )
      location.href = edit_post_path
    }
  }

  const handleFileSelect = event => {
    const newImage = event.target.files[0]
    const fileReader = new FileReader()
    fileReader.onloadend = (eventRoader: any) => {
      setImage({
        file: newImage,
        image_url: eventRoader.target.result,
      })
    }
    fileReader.readAsDataURL(newImage)
  }

  {
    /* オプション単位追加 */
  }
  const addPostOption = () => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          {
            /* オプション追加 */
          }
          tmp_item.post_options.push({
            post_item_id: undefined,
            title: '',
            description: '',
            is_required: true,
            is_multiple: true,
            option_items: [],
            deleted_at: '',
          })
        }
      })
      return newState
    })
  }

  {
    /* オプション単位削除 */
  }
  const removePostOption = (option_index: number) => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          {
            /* 削除は論理削除。該当削除オブジェクトに現在日時をセット。 */
          }
          tmp_item.post_options[option_index].deleted_at = moment().format('YYYY-MM-DD HH:mm:ssZ')
        }
      })
      return newState
    })
    // 削除に合わせて関連エラー削除しないと更新ボタンが押せなくなるため
    delete errors[`post_options_attributes[${option_index}][title]`]
    delete errors[`post_options_attributes[${option_index}][description]`]
    delete errors[`post_options_attributes[${option_index}][is_required]`]
    delete errors[`post_options_attributes[${option_index}][is_multiple]`]
  }

  {
    /* オプションアイテム単位追加 */
  }
  const addOptionItem = (index: number) => () => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          {
            /* 該当のオプションにアイテム追加 */
          }
          tmp_item.post_options[index].option_items.push({
            post_option_id: undefined,
            title: '',
            price: undefined,
            is_quantity_limited: false,
            quantity: undefined,
            total_ordered_count: 0,
            deleted_at: '',
          })
        }
      })
      return newState
    })
  }

  {
    /* オプションアイテム単位削除 */
  }
  const removeOptionItem = (option_index: number, item_index: number) => {
    setItems(prev => {
      const newState = [...prev]
      newState.map(tmp_item => {
        if (tmp_item === props.item) {
          {
            /* 削除は論理削除。該当削除オブジェクトに現在日時をセット。 */
          }
          tmp_item.post_options[option_index].option_items[item_index].deleted_at = moment().format(
            'YYYY-MM-DD HH:mm:ssZ'
          )
        }
      })
      return newState
    })

    // 削除に合わせて関連エラー削除しないと更新ボタンが押せなくなるため
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][title]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][price]`
    ]
    delete errors[
      `post_options_attributes[${option_index}][option_items_attributes][${item_index}][quantity]`
    ]
  }
  const postListBack = () => {
    setItems(prev => {
      const newState = [...prev]
      return newState.filter(item => item.id)
    })
    props.handleBack()
  }

  return (
    <>
      <Form
        fields={menuFields}
        handleUpdateForm={handleUpdateForm}
        handleSubmit={handleItemFormSubmit}
        customValidation={customValidation}
      >
        <ul>
          <S.FormItem>
            <InputText
              required={true}
              name="name"
              label={I18n.t('meta.post.name_menu')}
              defaultValue={props.item.name}
              error={errors.name}
            />
          </S.FormItem>
          <S.FormComment>*最大100文字</S.FormComment>
          <S.FormItem>
            <InputTextArea
              required={true}
              name="description"
              label={I18n.t('meta.post.description_menu')}
              defaultValue={props.item.description}
              error={errors.description}
              maxLength={200}
            />
            <S.FormComment>*最大200文字</S.FormComment>
          </S.FormItem>
          <S.FormItem>
            <InputText
              required={true}
              name="retail_price"
              label={I18n.t('generic.price_tax')}
              defaultValue={props.item.retail_price ? String(props.item.retail_price) : '0'}
              error={errors.retail_price}
              onChangeHandler={handlePriceChange}
            />
            <S.FormComment>*店舗販売価格と同額設定にご協力お願いいたします</S.FormComment>
          </S.FormItem>
          <S.FormItem>
            <Select
              required={true}
              name="item_category_id"
              label={I18n.t('meta.post.item_categories')}
              options={props.itemCategories.map(item => {
                return { label: item.name, value: item.id }
              })}
              defaultValue={
                props.item.item_category_id ? props.item.item_category_id.toString() : '1'
              }
              error={errors.item_category}
            />
          </S.FormItem>
          {/* <S.FormItem>
            <S.DeliveryFeeWrapper>
              <S.FormItemTitle>{I18n.t('post.wholesale_price')}</S.FormItemTitle>
              <div>¥{priceWithDeliveryFee}</div>
            </S.DeliveryFeeWrapper>
          </S.FormItem> */}
          <S.FormItem>
            <CheckBox
              label={I18n.t('post.quantity')}
              name="is_quantity_limited"
              defaultChecked={props.item.is_quantity_limited}
              onChangeHandler={setQuantityLimitedStatus}
            />
          </S.FormItem>
          <S.FormItem>
            <InputText
              name="quantity"
              label={I18n.t('post.max_nb_orders')}
              defaultValue={props.item.quantity ? String(props.item.quantity) : '0'}
              error={errors.quantity}
              readonly={!limitedItem}
              inputRef={quantityRef}
            />
          </S.FormItem>
          <S.FormItem>
            <S.DeliveryFeeWrapper>
              <S.FormItemTitle>{I18n.t('post.total_ordered_count')}</S.FormItemTitle>
              <div>
                {props.item.total_ordered_count ? String(props.item.total_ordered_count) : '0'}
              </div>
            </S.DeliveryFeeWrapper>
          </S.FormItem>
          <S.FormItem>
            <S.ImageWrapper>
              <S.FormItemTitle>{I18n.t('generic.image')}</S.FormItemTitle>
              {image && (
                <S.PreviewImage>
                  {image.image_url === '/images/no-avatar.svg' ? (
                    <p>画像がありません</p>
                  ) : (
                    <S.ItemImage>
                      <img src={image.image_url} />
                    </S.ItemImage>
                  )}
                  <S.ImageButtons>
                    <S.FileSelectWrapper>
                      <label htmlFor="fileSelectUploader">
                        {I18n.t('generic.change')}
                        <input
                          id="fileSelectUploader"
                          type="file"
                          accept="image/*"
                          onChange={handleFileSelect}
                        />
                      </label>
                    </S.FileSelectWrapper>
                  </S.ImageButtons>
                </S.PreviewImage>
              )}
              <S.DropZoneWrapper hasImage={image ? true : false}>
                <DropZoneUploader onDrop={onDropHandler} />
              </S.DropZoneWrapper>
            </S.ImageWrapper>
          </S.FormItem>
          {/* オプションコンポーネント */}
          <OptionForm
            item={props.item}
            addPostOption={addPostOption}
            removePostOption={removePostOption}
            postOptions={props.item.post_options}
            addOptionItem={addOptionItem}
            removeOptionItem={removeOptionItem}
            errors={errors}
          />
          {/* オプションコンポーネント */}
        </ul>
        <S.Footer>
          <S.ButtonLeft>
            <Button handleClick={postListBack}>{I18n.t('generic.back_to_index')}</Button>
          </S.ButtonLeft>
          <S.ButtonSave>
            <Button primary={true} disabled={!isSubmitEnabled}>
              {props.item.id ? I18n.t('generic.update') : I18n.t('generic.create')}
            </Button>
          </S.ButtonSave>
        </S.Footer>
      </Form>
    </>
  )
}

const S: { [key: string]: AnyStyledComponent } = {}
S.FormItem = styled.div`
  display: block;
  & + & {
    margin-top: 16px;
  }
`
S.FormComment = styled.div`
  font-size: 12px;
  padding-bottom: 16px;
  text-align: right;
  color: #777;
`
S.FormItemTitle = styled.p`
  width: 240px;
  margin-top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 100%;
    margin-bottom: 12px;
  }
`
S.Footer = styled.div`
  display: flex;
  margin-top: 24px;
  justify-content: flex-start;
`
S.DropZoneWrapper = styled.div<{ hasImage: boolean }>`
  display: block;
  width: 100%;
  flex: 1;
  ${({ hasImage }) =>
    hasImage &&
    `
    display: none;
  `}
`
S.ButtonLeft = styled.div`
  margin-right: 20px;
`

S.ButtonSave = styled.div`
  button {
    width: 100%;
  }
`

S.DeliveryFeeWrapper = styled.div`
  display: flex;

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ImageWrapper = styled.div<{ hasNoImage: boolean }>`
  display: flex;
  .DropZoneUploader {
    width: 100%;
    flex: 1;
    margin: 0;
  }

  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    flex-direction: column;
  }
`

S.ItemImage = styled.div`
  display: block;
  position: relative;
  width: 240px;
  height: inherit;
  background-color: rgb(244, 244, 244);
  border-radius: 3px;
  overflow: hidden;
  > img {
    width: inherit;
    height: inherit;
    object-fit: contain;
  }
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    width: 200px;
  }
`
S.NoImage = styled.p`
  width: 100%;
  height: 100%;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  background-color: #f4f4f4;
`
S.PreviewImage = styled.div`
  display: flex;
  flex: 1;
`
S.ImageButtons = styled.div`
  display: flex;
  flex: 1;
  max-width: 100px;
  text-align: center;
  margin-left: 50px;
  align-items: center;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    margin-left: 20px;
  }
`

S.FileSelectWrapper = styled.div`
  cursor: pointer;
  margin-right: 12px;
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 16px;
  @media (max-width: ${BREAKPOINT_TABLET_SMALL}px) {
    padding: 0 11px;
  }
  border-radius: 4px;
  outline: none;
  background-color: var(${THEME_COLOR_VARIABLE_NAME});
  color: #fff;
  border: none;

  &:hover {
    opacity: 0.6;
  }
  > a {
    color: #fff;
  }

  input {
    width: 0;
  }
`

export default PostItemEdit
