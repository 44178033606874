export const APP_NAME = 'SHARING ECONOMY CLONE SCRIPT'
export const THEME_COLOR_VARIABLE_NAME = '--theme-color'
export const HEADER_HEIGHT = 92
export const HEADER_HEIGHT_MOBILE = 55
export const BREAKPOINT_DESKTOP = 1088
export const BREAKPOINT_TABLET_LARGE = 960
export const BREAKPOINT_TABLET_SMALL = 720
export const BREAKPOINT_TABLET_MOBILE = 600
export const MAX_TEXT_COUNT = 100
export const MAX_TEXTAREA_COUNT = 5000
export const DEFAULT_PRICE = 5000
export const UPLOAD_FILE_MAX_SIZE = 20000000
export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_DISPLAY_FORMAT = 'YYYY/M/D'
export const DAYS_OF_THE_WEEK = ['日', '月', '火', '水', '木', '金', '土']
// DAYS_OF_THE_WEEKがカレンダーに使われているがカレンダーが削除になるぽい？のでそうであれば上記削除する
export const VALUES_OF_THE_WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat']
export const PHONE_INPUT_PATTERN = '^0[789]0[0-9]{4}[0-9]{4}$'
export const TEL_INPUT_PATTERN = '[0-9]{2,4}[0-9]{3,4}[0-9]{3,4}'
const paymentRequiredEl = document.getElementById('payment-required')
export const PAYMENT_REQUIRED = paymentRequiredEl
  ? paymentRequiredEl.getAttribute('data').toLowerCase() === 'true'
  : false

export const COLORS = {
  Primary: '#A7813F',
  PrimaryBlack: '#344351',
  Text: '#212529',
  TextLight: '#989AA1',
  TextPrimaryGray: '#989aa1',
  Border: '#EAEDEF',
  Danger: '#F66',
  Success: '#29C30E',
} as const

export const DAYS = [0, 1, 2, 3]

export const HOURS = [
  0,
  1,
  2,
  3,
  4,
  5,
  6,
  7,
  8,
  9,
  10,
  11,
  12,
  13,
  14,
  15,
  16,
  17,
  18,
  19,
  20,
  21,
  22,
  23,
]

export const MINUTES = [20, 25, 30, 40, 50, 60, 90, 120]

export const BUSINESS_TIMES_HOUR = [
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
  '24:00',
]

export const BUSINESS_TIMES = [
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30',
  '24:00',
]

export const BUSINESS_TIMES_BY_TEN = [
  '06:00',
  '06:10',
  '06:20',
  '06:30',
  '06:40',
  '06:50',
  '07:00',
  '07:10',
  '07:20',
  '07:30',
  '07:40',
  '07:50',
  '08:00',
  '08:10',
  '08:20',
  '08:30',
  '08:40',
  '08:50',
  '09:00',
  '09:10',
  '09:20',
  '09:30',
  '09:40',
  '09:50',
  '10:00',
  '10:10',
  '10:20',
  '10:30',
  '10:40',
  '10:50',
  '11:00',
  '11:10',
  '11:20',
  '11:30',
  '11:40',
  '11:50',
  '12:00',
  '12:10',
  '12:20',
  '12:30',
  '12:40',
  '12:50',
  '13:00',
  '13:10',
  '13:20',
  '13:30',
  '13:40',
  '13:50',
  '14:00',
  '14:10',
  '14:20',
  '14:30',
  '14:40',
  '14:50',
  '15:00',
  '15:10',
  '15:20',
  '15:30',
  '15:40',
  '15:50',
  '16:00',
  '16:10',
  '16:20',
  '16:30',
  '16:40',
  '16:50',
  '17:00',
  '17:10',
  '17:20',
  '17:30',
  '17:40',
  '17:50',
  '18:00',
  '18:10',
  '18:20',
  '18:30',
  '18:40',
  '18:50',
  '19:00',
  '19:10',
  '19:20',
  '19:30',
  '19:40',
  '19:50',
  '20:00',
  '20:10',
  '20:20',
  '20:30',
  '20:40',
  '20:50',
  '21:00',
  '21:10',
  '21:20',
  '21:30',
  '21:40',
  '21:50',
  '22:00',
  '22:10',
  '22:20',
  '22:30',
  '22:40',
  '22:50',
  '23:00',
  '23:10',
  '23:20',
  '23:30',
  '23:40',
  '23:50',
  '23:59',
]

// 変更時に書き換え バックエンドからもらえると安心かも
export const MIN_ORDER_PRICE = 700

export const ROLES = [
  { label: 'ゲスト', value: 1 },
  { label: '店舗ユーザー', value: 2 },
  { label: '管理者', value: 3 },
  { label: 'Taxiユーザー', value: 4 },
]

export const ORDER_TYPES = [
  { label: 'どちらも可', value: 'both' },
  { label: '配送のみ', value: 'only_delivery' },
  { label: 'お持ち帰りのみ', value: 'only_takeout' },
]

export const DEVICE_TYPES = [
  { label: '店舗自社デバイス', value: 'ipad' },
  { label: '貸出クラウドプリンタ', value: 'cloud_printer' },
]
